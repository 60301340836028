import React, { useState, useEffect } from 'react';
import { FaTruck, FaEdit, FaTrash } from 'react-icons/fa';
import useAxios from '../../hook/useAxios';
import toast from 'react-hot-toast';
import Modal from '../../Components/Dashboard/Modal';
import Skeleton from 'react-loading-skeleton';

const Suppliers = () => {
    const [suppliers, setSuppliers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentSupplier, setCurrentSupplier] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        contactPerson: '',
        phone: '',
        email: '',
        address: '',
        paymentMade: false,
        totalPaid: 0
    });

    const token = localStorage.getItem('token');

    const fetchSuppliers = useAxios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/suppliers`,
        headers: { Authorization: `Bearer ${token}` }
    }, (data) => {
        setSuppliers(data);
        setLoading(false);
    }, (error) => {
        if (error.response && error.response.status !== 401) {
            console.error('Error fetching suppliers:', error);
            toast.error('Error fetching suppliers');
            setLoading(false);
        }
    });

    useEffect(() => {
        fetchSuppliers.execute();
    }, []);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const openModal = (supplier = null) => {
        setCurrentSupplier(supplier);
        if (supplier) {
            setFormData(supplier);
        } else {
            setFormData({
                name: '',
                contactPerson: '',
                phone: '',
                email: '',
                address: '',
                paymentMade: false,
                totalPaid: 0
            });
        }
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setCurrentSupplier(null);
    };

    const saveSupplier = useAxios({
        method: currentSupplier ? 'put' : 'post',
        url: currentSupplier ? `${process.env.REACT_APP_API_URL}/api/suppliers/${currentSupplier._id}` : `${process.env.REACT_APP_API_URL}/api/suppliers`,
        headers: { Authorization: `Bearer ${token}` },
        data: formData
    }, (data) => {
        toast.success(`Proveedor ${currentSupplier ? 'actualizado' : 'agregado'} exitosamente`);
        fetchSuppliers.execute();
        closeModal();
    }, (error) => {
        toast.error(`Error al ${currentSupplier ? 'actualizar' : 'agregar'} proveedor`);
        console.error(`Error ${currentSupplier ? 'updating' : 'adding'} supplier:`, error);
    });

    const deleteSupplier = useAxios({
        method: 'delete',
        url: `${process.env.REACT_APP_API_URL}/api/suppliers/${currentSupplier?._id}`,
        headers: { Authorization: `Bearer ${token}` }
    }, () => {
        toast.success('Proveedor eliminado exitosamente');
        fetchSuppliers.execute();
    }, (error) => {
        toast.error('Error al eliminar proveedor');
        console.error('Error deleting supplier:', error);
    });

    const handleSaveSupplier = (e) => {
        e.preventDefault();
        saveSupplier.execute();
    };

    const columns = [
        {
            Header: 'Nombre',
            accessor: 'name'
        },
        {
            Header: 'Contacto',
            accessor: 'contactPerson'
        },
        {
            Header: 'Teléfono',
            accessor: 'phone'
        },
        {
            Header: 'Correo Electrónico',
            accessor: 'email'
        },
        {
            Header: 'Dirección',
            accessor: 'address'
        },
        {
            Header: 'Pago Realizado',
            accessor: 'paymentMade',
            Cell: ({ value }) => (
                <div className={`h-4 w-4 rounded-full ${value ? 'bg-green-500' : 'bg-red-500'}`}></div>
            )
        },
        {
            Header: 'Total Pagado',
            accessor: 'totalPaid'
        },
        {
            Header: 'Acciones',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div className="flex space-x-2">
                    <button onClick={() => openModal(row.original)} className="text-blue-500 hover:text-blue-700"><FaEdit /></button>
                    <button onClick={() => { setCurrentSupplier(row.original); deleteSupplier.execute(); }} className="text-red-500 hover:text-red-700"><FaTrash /></button>
                </div>
            )
        }
    ];

    return (
        <div className="md:ml-[256px] p-8 min-h-screen flex flex-col overflow-hidden text-white">
            <h1 className="text-2xl font-semibold mb-6">Proveedores</h1>
            <button onClick={() => openModal()} className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4">
                <FaTruck className="inline mr-2" /> Nuevo Proveedor
            </button>
            {loading ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                    {Array.from({ length: 8 }).map((_, index) => (
                        <div key={index} className="p-4 bg-gray-800 rounded shadow">
                            <Skeleton height={20} width="80%" />
                            <Skeleton height={20} width="60%" />
                            <Skeleton height={20} width="40%" />
                        </div>
                    ))}
                </div>
            ) : (
                <div className="overflow-x-auto">
                    <div className="hidden md:block">
                        <table className="min-w-full bg-gray-800 border rounded-md shadow">
                            <thead className="bg-gray-700">
                                <tr>
                                    {columns.map((column) => (
                                        <th key={column.accessor} className="p-2 md:p-4 text-left">
                                            {column.Header}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {suppliers.map((supplier) => (
                                    <tr key={supplier._id} className="border-t border-gray-700">
                                        {columns.map((column) => (
                                            <td key={column.accessor} className="p-2 md:p-4">
                                                {column.accessor === 'actions'
                                                    ? column.Cell({ row: { original: supplier } })
                                                    : column.accessor === 'paymentMade'
                                                        ? column.Cell({ value: supplier[column.accessor] })
                                                        : supplier[column.accessor]}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="md:hidden">
                        {suppliers.map((supplier) => (
                            <div key={supplier._id} className="border rounded-md p-4 mb-4 shadow bg-gray-800">
                                {columns.map((column) => (
                                    <div key={column.accessor} className="flex justify-between mb-2">
                                        <span className="font-semibold">{column.Header}</span>
                                        <span>
                                            {column.accessor === 'actions'
                                                ? column.Cell({ row: { original: supplier } })
                                                : column.accessor === 'paymentMade'
                                                    ? column.Cell({ value: supplier[column.accessor] })
                                                    : supplier[column.accessor]}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {modalOpen && (
                <Modal onClose={closeModal}>
                    <form onSubmit={handleSaveSupplier}>
                        <div className="space-y-4">
                            <div>
                                <label className="block text-gray-300">Nombre</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    className="w-full border border-gray-600 bg-gray-700 text-white rounded-lg px-3 py-2"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-300">Persona de Contacto</label>
                                <input
                                    type="text"
                                    name="contactPerson"
                                    value={formData.contactPerson}
                                    onChange={handleInputChange}
                                    className="w-full border border-gray-600 bg-gray-700 text-white rounded-lg px-3 py-2"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-300">Teléfono</label>
                                <input
                                    type="text"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    className="w-full border border-gray-600 bg-gray-700 text-white rounded-lg px-3 py-2"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-300">Correo Electrónico</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    className="w-full border border-gray-600 bg-gray-700 text-white rounded-lg px-3 py-2"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-300">Dirección</label>
                                <input
                                    type="text"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleInputChange}
                                    className="w-full border border-gray-600 bg-gray-700 text-white rounded-lg px-3 py-2"
                                />
                            </div>
                            <div className="flex items-center">
                                <label className="block text-gray-300 mr-2">Pago Realizado</label>
                                <input
                                    type="checkbox"
                                    name="paymentMade"
                                    checked={formData.paymentMade}
                                    onChange={handleInputChange}
                                    className="mr-2"
                                />
                            </div>
                            <div>
                                <label className="block text-gray-300">Total Pagado</label>
                                <input
                                    type="number"
                                    name="totalPaid"
                                    value={formData.totalPaid}
                                    onChange={handleInputChange}
                                    className="w-full border border-gray-600 bg-gray-700 text-white rounded-lg px-3 py-2"
                                />
                            </div>
                        </div>
                        <div className="mt-6 flex justify-end">
                            <button type="submit" className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                Guardar
                            </button>
                        </div>
                    </form>
                </Modal>
            )}
        </div>
    );
};

export default Suppliers;
