import React from 'react';

const KpiCard = ({ icon, title, value, color }) => {
    return (
        <div className="bg-gray-800 shadow rounded-lg p-6 flex flex-col items-start space-y-4">
            <div className={`p-3 rounded-full ${color}`}>
                {icon}
            </div>
            <div>
                <p className="text-sm font-semibold text-gray-400">{title}</p>
                <p className="text-2xl font-bold text-white">{value}</p>
            </div>
        </div>
    );
};

export default KpiCard;
