import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import axios from 'axios';

const SupportPage = () => {
    const [subject, setSubject] = useState('');
    const [explanation, setExplanation] = useState('');
    const [priority, setPriority] = useState('medio');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const token = localStorage.getItem('token');
            const userEmail = localStorage.getItem('email');
            const userName = localStorage.getItem('name');

            const supportData = {
                subject,
                explanation,
                priority,
                email: userEmail,
                name: userName
            };

            await axios.post(`${process.env.REACT_APP_API_URL}/api/support`, supportData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            toast.success('Solicitud de soporte enviada exitosamente.');
            setSubject('');
            setExplanation('');
            setPriority('medio');
        } catch (error) {
            console.error('Error enviando la solicitud de soporte:', error);
            toast.error('Error enviando la solicitud de soporte.');
        }
    };

    return (
        <div className="md:ml-[256px] p-8 min-h-screen flex text-white flex-col overflow-hidden">
            <h1 className="text-2xl font-semibold mb-4">Soporte</h1>
            <form onSubmit={handleSubmit} className="bg-gray-800 text-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className="mb-4">
                    <label className="block text-gray-300 text-sm font-bold mb-2" htmlFor="subject">
                        Asunto
                    </label>
                    <input
                        type="text"
                        id="subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        required
                        className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 text-gray-300 bg-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-300 text-sm font-bold mb-2" htmlFor="explanation">
                        Explicación
                    </label>
                    <textarea
                        id="explanation"
                        value={explanation}
                        onChange={(e) => setExplanation(e.target.value)}
                        required
                        className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 text-gray-300 bg-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                    ></textarea>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-300 text-sm font-bold mb-2" htmlFor="priority">
                        Prioridad
                    </label>
                    <select
                        id="priority"
                        value={priority}
                        onChange={(e) => setPriority(e.target.value)}
                        required
                        className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 text-gray-300 bg-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                    >
                        <option value="urgente">Urgente</option>
                        <option value="alto">Alto</option>
                        <option value="medio">Medio</option>
                        <option value="bajo">Bajo</option>
                    </select>
                </div>
                <div className="flex items-center justify-between">
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Enviar
                    </button>
                </div>
            </form>
        </div>
    );
};

export default SupportPage;
