import React from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import Login from './Pages/Login/login';
import Dashboard from './Pages/Home/Dashboard';
import Products from './Pages/Home/Products';
import Main from './Pages/Home/Main';
import Sales from './Pages/Home/Sales';
import NewSale from './Pages/Home/NewSale';
import CalendarPage from './Pages/Home/CalendarPage';
import Settings from './Pages/Home/Settings';
import TeamPage from './Pages/Home/TeamPage';
import { Toaster } from 'react-hot-toast';
import Clients from './Pages/Home/Clients';
import Billing from './Pages/Home/Billing';
import Suppliers from './Pages/Home/Suppliers';
import Cashbox from './Pages/Home/Cashbox';
import LicensePage from './Pages/Home/LicensePage';
import SupportPage from './Pages/Home/SupportPage';
import LandingPage from './Pages/Landing/LandingPage';

const plans = ['Free', 'Basic', 'Standard', 'Premium'];

const PrivateRoute = ({ roles, requiredPlan, children }) => {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');
    const userPlan = localStorage.getItem('plan');
    const planExpiry = localStorage.getItem('planExpiry');

    if (!token) {
        return <Navigate to="/" />;
    }

    if (roles && !roles.includes(role)) {
        return <Navigate to="/dashboard" />;
    }

    const userPlanIndex = plans.indexOf(userPlan);
    const requiredPlanIndex = plans.indexOf(requiredPlan);

    if (userPlanIndex < requiredPlanIndex || new Date(planExpiry) < new Date()) {
        return <Navigate to="/dashboard" />;
    }

    return children ? children : <Outlet />;
};

function App() {
    const [theme] = React.useState('light');
    const apiUrl = process.env.REACT_APP_API_URL;

    return (
        <div className={`App ${theme === 'light' ? 'theme-light' : 'theme-dark'}`}>
            <Toaster position="top-center" reverseOrder={false} />
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<Login apiUrl={apiUrl} />} />
                <Route path="/register" element={<Login apiUrl={apiUrl} formState="register" />} />
                <Route path="dashboard" element={<PrivateRoute><Dashboard apiUrl={apiUrl} /></PrivateRoute>}>
                    <Route index element={<Main apiUrl={apiUrl} />} />
                    <Route path="products" element={<PrivateRoute requiredPlan="Free"><Products apiUrl={apiUrl} /></PrivateRoute>} />
                    <Route path="sales" element={<PrivateRoute requiredPlan="Free"><Sales apiUrl={apiUrl} /></PrivateRoute>} />
                    <Route path="new-sale" element={<PrivateRoute requiredPlan="Free"><NewSale apiUrl={apiUrl} /></PrivateRoute>} />
                    <Route path="clients" element={<PrivateRoute requiredPlan="Basic"><Clients apiUrl={apiUrl} /></PrivateRoute>} />
                    <Route path="license" element={<PrivateRoute roles={['admin']} requiredPlan="Basic"><LicensePage apiUrl={apiUrl} /></PrivateRoute>} />
                    <Route path="calendar" element={<PrivateRoute requiredPlan="Standard"><CalendarPage apiUrl={apiUrl} /></PrivateRoute>} />
                    <Route path="cashbox" element={<PrivateRoute requiredPlan="Standard"><Cashbox apiUrl={apiUrl} /></PrivateRoute>} />
                    <Route path="suppliers" element={<PrivateRoute requiredPlan="Standard"><Suppliers apiUrl={apiUrl} /></PrivateRoute>} />
                    <Route path="billing" element={<PrivateRoute roles={['admin']} requiredPlan="Premium"><Billing apiUrl={apiUrl} /></PrivateRoute>} />
                    <Route path="team" element={<PrivateRoute roles={['admin']} requiredPlan="Premium"><TeamPage apiUrl={apiUrl} /></PrivateRoute>} />
                    <Route path="settings" element={<PrivateRoute roles={['admin']} requiredPlan="Free"><Settings apiUrl={apiUrl} /></PrivateRoute>} />
                    <Route path="support" element={<PrivateRoute><SupportPage apiUrl={apiUrl} /></PrivateRoute>} />
                </Route>
            </Routes>
        </div>
    );
}

export default App;
