import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

const useAxios = (config, onSuccess, onError) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const execute = async (overrideConfig) => {
        setLoading(true);
        const finalConfig = { ...config, ...overrideConfig };
        try {
            const response = await axios(finalConfig);
            setData(response.data);
            if (onSuccess) {
                onSuccess(response.data);
            }
        } catch (err) {
            setError(err);
            if (axios.isAxiosError(err)) {
                const errorMessage = err.response?.data?.message || 'Error en la solicitud';
                const errorId = `error-${err.response?.status}-${errorMessage}`;

                if (err.response?.status === 401) {
                    if (err.response?.data?.message === 'Token expirado, por favor inicie sesión de nuevo') {
                        toast.error('Tu sesión ha expirado, por favor inicia sesión de nuevo.', { id: errorId });
                        localStorage.removeItem('token'); // Clear the expired token
                        setTimeout(() => {
                            navigate('/'); // Redirect to the login page
                        }, 1000); // Adjust the time if necessary
                    } else {
                        console.error(errorMessage);
                    }
                } else if (err.response?.status !== 404) {
                    console.error(errorMessage);
                }
            } else {
                const networkErrorId = 'network-error';
                toast.error('Error de red o problema del servidor', { id: networkErrorId });
            }

            if (onError) {
                onError(err);
            }
        } finally {
            setLoading(false);
        }
    };

    return { data, loading, error, execute };
};

export default useAxios;
