import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaKey, FaEdit, FaCalendarAlt, FaUsers } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import Modal from '../../Components/Dashboard/Modal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import '../../assets/Main.css'; // Asegúrate de importar el archivo CSS

const LicensePage = () => {
    const [licenseData, setLicenseData] = useState({});
    const [loading, setLoading] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [licenseCode, setLicenseCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('No token found');
                    return;
                }

                const licenseResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/license/company`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                });

                const licenseData = licenseResponse.data;
                const planExpiryDate = new Date(licenseData.expire);

                setLicenseData({
                    code: licenseData.code,
                    type: licenseData.type,
                    planExpiry: planExpiryDate.toISOString().substr(0, 10),
                    remainAccounts: licenseData.remainAccounts,
                    totalAccounts: licenseData.totalAccounts
                });
                setLoading(false);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    setErrorMessage('Session expired, please log in again.');
                } else {
                    console.error('Error fetching license data:', error);
                }
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleEditClick = () => {
        setEditMode(!editMode);
        setModalOpen(!editMode);
    };

    const handleSave = async () => {
        try {
            const token = localStorage.getItem('token');
            await axios.post(`${process.env.REACT_APP_API_URL}/api/license/apply`, { code: licenseCode }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const licenseResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/license/company`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            const licenseData = licenseResponse.data;
            const planExpiryDate = new Date(licenseData.expire);

            setLicenseData({
                code: licenseData.code,
                type: licenseData.type,
                planExpiry: planExpiryDate.toISOString().substr(0, 10),
                remainAccounts: licenseData.remainAccounts,
                totalAccounts: licenseData.totalAccounts
            });

            setModalOpen(false);
            setEditMode(false);
            toast.success('Licencia aplicada exitosamente.');
        } catch (error) {
            console.error('Error saving license data:', error);
            toast.error('Error aplicando la licencia.');
        }
    };

    const percentageUsed = ((licenseData.totalAccounts - licenseData.remainAccounts) / licenseData.totalAccounts) * 100;

    return (
        <div className="md:ml-[256px] p-8 min-h-screen relative dark:bg-gray-900">
            {errorMessage && <div className="bg-red-500 text-white p-4 rounded mb-4">{errorMessage}</div>}
            <div className="mb-4 flex justify-between items-center">
                <h1 className="text-xl md:text-2xl font-semibold text-gray-800 dark:text-gray-100">Licencia</h1>
                <button onClick={handleEditClick} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    {editMode ? 'Terminar' : 'Editar'} <FaEdit className="inline ml-2" />
                </button>
            </div>
            <h2 className="text-lg md:text-xl font-semibold text-gray-800 dark:text-gray-100">Detalles de la Licencia</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {loading ? (
                    <div className="bg-white dark:bg-gray-800 shadow rounded p-4">
                        <Skeleton height={64} width={64} />
                        <div className="mt-4">
                            <Skeleton height={20} width="80%" />
                            <Skeleton height={20} width="60%" />
                            <Skeleton height={20} width="40%" />
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="bg-white dark:bg-gray-800 shadow rounded p-4">
                            <div className="flex flex-col">
                                <div className="flex items-center mb-2">
                                    <FaKey className="text-4xl text-blue-600 dark:text-blue-400 mr-4" />
                                    <div>
                                        <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-100">Tipo de Licencia</h3>
                                        <p className="text-gray-800 dark:text-gray-300">{licenseData.type}</p>
                                    </div>
                                </div>
                                <div className="flex items-center mb-2">
                                    <FaKey className="text-4xl text-blue-600 dark:text-blue-400 mr-4" />
                                    <div>
                                        <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-100">Código de Licencia</h3>
                                        <p className="break-all text-gray-800 dark:text-gray-300">{licenseData.code}</p>
                                    </div>
                                </div>
                                <div className="flex items-center mb-2">
                                    <FaCalendarAlt className="text-4xl text-blue-600 dark:text-blue-400 mr-4" />
                                    <div>
                                        <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-100">Fecha de Expiración</h3>
                                        <p className="text-gray-800 dark:text-gray-300">{new Date(licenseData.planExpiry).toLocaleDateString()}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white dark:bg-gray-800 shadow rounded p-4 flex flex-col items-center w-full md:w-1/2">
                            <div className="relative w-full h-8 mb-2">
                                <div className="absolute top-0 left-0 w-full h-full bg-gray-200 dark:bg-gray-700 rounded-full"></div>
                                <div
                                    className="absolute top-0 left-0 h-full bg-blue-500 dark:bg-blue-400 rounded-full"
                                    style={{ width: `${percentageUsed}%` }}
                                ></div>
                            </div>
                            <div className="mt-4 text-center">
                                <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-100"><FaUsers className="inline mr-2" />Licencias Usadas</h3>
                                <p className="text-gray-800 dark:text-gray-300">{licenseData.totalAccounts - licenseData.remainAccounts} / {licenseData.totalAccounts}</p>
                            </div>
                        </div>
                    </>
                )}
            </div>
            {editMode && modalOpen && (
                <Modal onClose={() => setModalOpen(false)}>
                    <h3 className="text-lg font-semibold mb-4 text-gray-800 dark:text-gray-100">Aplicar Licencia</h3>
                    <div>
                        <label className="block mb-2 text-gray-800 dark:text-gray-100">Código de Licencia</label>
                        <input
                            type="text"
                            value={licenseCode}
                            onChange={(e) => setLicenseCode(e.target.value)}
                            className="border p-2 rounded w-full dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300"
                        />
                    </div>
                    <button
                        onClick={handleSave}
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4"
                    >
                        Aplicar
                    </button>
                </Modal>
            )}
        </div>
    );
};

export default LicensePage;
