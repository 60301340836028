import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import NavBar from '../../Components/Dashboard/NavBar';
import SideBar from '../../Components/Dashboard/SideBar';
import { ThemeContext } from '../../config/ThemeContext'; // Asegúrate de que la ruta sea correcta

function Dashboard() {
    
    return (
        <div className=" flex ">
            <SideBar />
            <div className="flex flex-col flex-1">
                <NavBar />
                <main className="flex-1 bg-gray-900 overflow-y-auto p-4 pt-16">
                    <Outlet /> {/* Aquí se renderizarán las rutas anidadas */}
                </main>
            </div>
        </div>
    );
}

export default Dashboard;
