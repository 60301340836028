import React from "react";
import { Link } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { useSpring, animated } from "react-spring";
import dashimg from "../../assets/img/dash.png";
import salesimg from "../../assets/img/Ventas.png";
import invoiceimg from "../../assets/img/factura.png";
import posaurlogo from "../../assets/img/posaurlogo.png";

const LandingPage = () => {
  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 300,
  });

  return (
    <div className="bg-gray-100">
      <header className="bg-blue-900 text-white">
        <div className="container mx-auto flex justify-between items-center py-4 px-6">
          <div className="bg-white p-1 rounded-md">
            <img
              src={posaurlogo}
              alt="POSAUR Logo"
              className="h-12 rounded-md"
            />
          </div>
          <nav className="flex space-x-4">
            <Link to="/login" className="hover:underline">
              Login
            </Link>
          </nav>
        </div>
      </header>
      <section className="bg-blue-900 text-white text-center py-20">
        <animated.div style={fadeIn}>
          <div className="container mx-auto">
            <h2 className="text-4xl font-bold mb-4">
              El mejor sistema POS para tu negocio
            </h2>
            <p className="text-xl mb-8">
              Fácil de usar, potente y confiable. Lleva tu negocio al siguiente
              nivel con POSAUR.
            </p>
            <Link
              to="/register"
              className="bg-green-500 text-white py-3 px-6 rounded hover:bg-green-600 text-lg"
            >
              Empieza Gratis
            </Link>
          </div>
        </animated.div>
      </section>
      <section className="bg-gray-100 py-20">
        <div className="container mx-auto text-center">
          <h3 className="text-3xl font-bold mb-12">
            Vista General del Dashboard
          </h3>
          <div className="flex justify-center">
            <img
              src={dashimg}
              alt="Dashboard Overview"
              className="rounded-lg shadow-lg"
            />
          </div>
        </div>
      </section>
      <section className="py-20">
        <div className="container mx-auto text-center">
          <h3 className="text-3xl font-bold mb-12">Manejo de Ventas</h3>
          <div className="grid md:grid-cols-2 gap-8">
            <div>
              <img
                src={salesimg}
                alt="Sales Management"
                className="rounded-lg shadow-lg"
              />
            </div>
            <div className="flex flex-col justify-center">
              <h4 className="text-2xl font-bold mb-4">
                Administra tus ventas fácilmente
              </h4>
              <p>
                Con nuestro sistema POSAUR, puedes gestionar tus ventas de
                manera eficiente y sin complicaciones, aumentando la
                productividad de tu negocio.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-100 py-20">
        <div className="container mx-auto text-center">
          <h3 className="text-3xl font-bold mb-12">
            Facturas Básicas por Correo
          </h3>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="flex flex-col justify-center">
              <h4 className="text-2xl font-bold mb-4">
                Envío de facturas por correo
              </h4>
              <p>
                Envía facturas a tus clientes directamente por correo
                electrónico de manera rápida y sencilla, mejorando la
                experiencia del cliente.
              </p>
            </div>
            <div>
              <img
                src={invoiceimg}
                alt="Email Invoices"
                className="rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="py-20">
        <div className="container mx-auto text-center">
          <h3 className="text-3xl font-bold mb-12">¿Por qué elegir POSAUR?</h3>
          <div className="grid md:grid-cols-3 gap-8">
            <animated.div
              style={fadeIn}
              className="bg-white p-8 rounded-lg shadow-lg"
            >
              <FaCheckCircle className="text-blue-900 text-4xl mx-auto mb-4" />
              <h4 className="text-2xl font-bold mb-2">Fácil de Usar</h4>
              <p>
                Interfaz intuitiva que te permite gestionar tu negocio sin
                complicaciones.
              </p>
            </animated.div>
            <animated.div
              style={fadeIn}
              className="bg-white p-8 rounded-lg shadow-lg"
            >
              <FaCheckCircle className="text-blue-900 text-4xl mx-auto mb-4" />
              <h4 className="text-2xl font-bold mb-2">Potente y Flexible</h4>
              <p>
                Funcionalidades avanzadas que se adaptan a las necesidades de tu
                negocio.
              </p>
            </animated.div>
            <animated.div
              style={fadeIn}
              className="bg-white p-8 rounded-lg shadow-lg"
            >
              <FaCheckCircle className="text-blue-900 text-4xl mx-auto mb-4" />
              <h4 className="text-2xl font-bold mb-2">Soporte Confiable</h4>
              <p>
                Nuestro equipo de soporte está disponible para ayudarte en todo
                momento.
              </p>
            </animated.div>
          </div>
        </div>
      </section>
      <section className="bg-gray-100 py-20">
        <div className="container mx-auto text-center">
          <h3 className="text-3xl font-bold mb-12">Nuestros Planes</h3>
          <div className="grid md:grid-cols-4 gap-8">
            <animated.div
              style={fadeIn}
              className="bg-white p-8 rounded-lg shadow-lg"
            >
              <h4 className="text-2xl font-bold mb-4">Free</h4>
              <p className="text-4xl font-bold text-green-500 mb-6">Gratis</p>
              <ul className="text-left mb-6">
                <li className="mb-2">Acceso a funciones básicas</li>
                <li className="mb-2">Soporte limitado</li>
                <li className="mb-2">Prueba gratuita de 15 días</li>
              </ul>
              <Link
                to="/register"
                className="bg-blue-900 text-white py-2 px-4 rounded hover:bg-blue-700 text-lg w-full block text-center"
              >
                Empieza Gratis
              </Link>
            </animated.div>
            <animated.div
              style={fadeIn}
              className="bg-white p-8 rounded-lg shadow-lg"
            >
              <h4 className="text-2xl font-bold mb-4">Basic</h4>
              <p className="text-4xl font-bold text-green-500 mb-6">$15/mes</p>
              <ul className="text-left mb-6">
                <li className="mb-2">Funciones avanzadas</li>
                <li className="mb-2">Soporte básico</li>
                <li className="mb-2">Acceso a clientes y licencias</li>
              </ul>
              <Link
                to="/register"
                className="bg-blue-900 text-white py-2 px-4 rounded hover:bg-blue-700 text-lg w-full block text-center"
              >
                Empieza con Basic
              </Link>
            </animated.div>
            <animated.div
              style={fadeIn}
              className="relative bg-white p-8 rounded-lg shadow-lg"
            >
              <div className="absolute top-0 right-0 bg-red-500 text-white text-xs px-2 py-1 rounded-bl-lg">
                Oferta
              </div>
              <h4 className="text-2xl font-bold mb-4">Standard</h4>
              <p className="text-4xl font-bold text-green-500 mb-6">
                <span className="line-through">$40/mes</span> $30/mes
              </p>
              <ul className="text-left mb-6">
                <li className="mb-2">Todo lo de Basic</li>
                <li className="mb-2">Gestión de calendario</li>
                <li className="mb-2">Corte de caja</li>
                <li className="mb-2">Gestión de proveedores</li>
                <li className="mb-2">Reportes y análisis avanzados</li>
                <li className="mb-2">Soporte standard</li>
              </ul>
              <Link
                to="/register"
                className="bg-blue-900 text-white py-2 px-4 rounded hover:bg-blue-700 text-lg w-full block text-center"
              >
                Empieza con Standard
              </Link>
            </animated.div>
            <animated.div
              style={fadeIn}
              className="bg-white p-8 rounded-lg shadow-lg"
            >
              <h4 className="text-2xl font-bold mb-4">Premium</h4>
              <p className="text-4xl font-bold text-green-500 mb-6">$50/mes</p>
              <ul className="text-left mb-6">
                <li className="mb-2">Todo lo de Standard</li>
                <li className="mb-2">Facturación avanzada</li>
                <li className="mb-2">Gestión de equipos</li>
                <li className="mb-2">Soporte prioritario</li>
              </ul>
              <Link
                to="/register"
                className="bg-blue-900 text-white py-2 px-4 rounded hover:bg-blue-700 text-lg w-full block text-center"
              >
                Empieza con Premium
              </Link>
            </animated.div>
          </div>
        </div>
      </section>
      <section className="bg-gray-100 py-12">
        <div className="container mx-auto text-center text-sm">
          <p>Obten tu licencia o paquetes especiales contactando</p>
          <p>
            <a href="mailto:info@tridniostudios.com" className="text-blue-500">
              info@tridniostudios.com
            </a>
          </p>
          <p>+506 84839675</p>
        </div>
      </section>
      <footer className="bg-blue-900 text-white py-6">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 POSAUR. Todos los derechos reservados.</p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
