import React from 'react';
import { Page, Text, View, Document, Image, StyleSheet, Font } from '@react-pdf/renderer';
import DejaVuSans from '../../assets/Fonts/dejavu_sans/DejaVuSans.ttf';

// Registrar la fuente DejaVu Sans desde el directorio local
Font.register({
  family: 'DejaVu Sans',
  fonts: [
    { src: DejaVuSans, fontWeight: 400 },
  ],
});

const commonStyles = {
  page: {
    padding: 40,
    fontSize: 12,
    lineHeight: 1.6,
    fontFamily: 'DejaVu Sans', // Usar la fuente DejaVu Sans registrada
  },
  header: {
    fontSize: 24,
    marginBottom: 20,
  },
  section: {
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableCell: {
    margin: 5,
  },
  total: {
    marginTop: 20,
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'right',
  },
};

const stylesTemplate1 = StyleSheet.create({
  ...commonStyles,
  page: {
    ...commonStyles.page,
    backgroundColor: '#f4f4f4',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    backgroundColor: '#0A1F44',
    padding: 10,
    color: 'white',
  },
  header: {
    fontSize: 24,
    color: 'white',
  },
  section: {
    marginBottom: 10,
    padding: 10,
  },
  text: {
    fontSize: 12,
    color: '#0A1F44',
  },
  table: {
    ...commonStyles.table,
    backgroundColor: 'white',
    margin: 10,
  },
  tableHeader: {
    backgroundColor: '#0A1F44',
    color: 'white',
  },
  tableRow: {
    ...commonStyles.tableRow,
  },
  tableCol: {
    ...commonStyles.tableCol,
    padding: 5,
  },
  tableCell: {
    ...commonStyles.tableCell,
  },
  total: {
    ...commonStyles.total,
    margin: 10,
    color: '#0A1F44',
  },
});

const stylesTemplate2 = StyleSheet.create({
  ...commonStyles,
  page: {
    ...commonStyles.page,
    backgroundColor: '#fff',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    borderBottom: '1px solid #000',
    paddingBottom: 10,
  },
  header: {
    fontSize: 24,
    color: '#000',
  },
  section: {
    marginBottom: 10,
    padding: 10,
  },
  text: {
    fontSize: 12,
    color: '#000',
  },
  table: {
    ...commonStyles.table,
    margin: 10,
  },
  tableHeader: {
    backgroundColor: '#000',
    color: 'white',
  },
  tableRow: {
    ...commonStyles.tableRow,
  },
  tableCol: {
    ...commonStyles.tableCol,
    padding: 5,
  },
  tableCell: {
    ...commonStyles.tableCell,
  },
  total: {
    ...commonStyles.total,
    margin: 10,
    color: '#000',
  },
});

const stylesTemplate3 = StyleSheet.create({
  ...commonStyles,
  page: {
    ...commonStyles.page,
    backgroundColor: '#eef7f8',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    backgroundColor: '#A3C4BC',
    padding: 10,
    color: 'white',
  },
  header: {
    fontSize: 24,
    color: 'white',
  },
  section: {
    marginBottom: 10,
    padding: 10,
  },
  text: {
    fontSize: 12,
    color: '#34656D',
  },
  table: {
    ...commonStyles.table,
    backgroundColor: 'white',
    margin: 10,
  },
  tableHeader: {
    backgroundColor: '#A3C4BC',
    color: 'white',
  },
  tableRow: {
    ...commonStyles.tableRow,
  },
  tableCol: {
    ...commonStyles.tableCol,
    padding: 5,
  },
  tableCell: {
    ...commonStyles.tableCell,
  },
  total: {
    ...commonStyles.total,
    margin: 10,
    color: '#34656D',
  },
});

export const InvoiceTemplate1 = ({ invoice = {}, logoUrl }) => {
  const { client = {}, items = [], invoiceNumber = '', date = '', total = 0, tax = 0 } = invoice;
  const { name = 'N/A', address = 'N/A', email = 'N/A', phone = 'N/A' } = client || {};

  const formattedTotal = typeof total === 'number' ? total.toFixed(2) : '0.00';
  const formattedTax = typeof tax === 'number' ? tax.toFixed(2) : '0.00';

  return (
    <Document>
      <Page style={stylesTemplate1.page}>
        <View style={stylesTemplate1.headerContainer}>
          <Text style={stylesTemplate1.header}>Factura</Text>
        </View>
        <View style={stylesTemplate1.section}>
          <Text style={stylesTemplate1.text}>Nombre del Cliente: {name}</Text>
          <Text style={stylesTemplate1.text}>Dirección: {address}</Text>
          <Text style={stylesTemplate1.text}>Email: {email}</Text>
          <Text style={stylesTemplate1.text}>Teléfono: {phone}</Text>
        </View>
        <View style={stylesTemplate1.section}>
          <Text style={stylesTemplate1.text}>Número de Factura: {invoiceNumber}</Text>
          <Text style={stylesTemplate1.text}>Fecha: {date}</Text>
        </View>
        <View style={stylesTemplate1.table}>
          <View style={[stylesTemplate1.tableRow, stylesTemplate1.tableHeader]}>
            <View style={stylesTemplate1.tableCol}>
              <Text style={stylesTemplate1.tableCell}>Descripción</Text>
            </View>
            <View style={stylesTemplate1.tableCol}>
              <Text style={stylesTemplate1.tableCell}>Cantidad</Text>
            </View>
            <View style={stylesTemplate1.tableCol}>
              <Text style={stylesTemplate1.tableCell}>Precio Unitario</Text>
            </View>
            <View style={stylesTemplate1.tableCol}>
              <Text style={stylesTemplate1.tableCell}>Total</Text>
            </View>
          </View>
          {items.map((item, index) => (
            <View key={index} style={stylesTemplate1.tableRow}>
              <View style={stylesTemplate1.tableCol}>
                <Text style={stylesTemplate1.tableCell}>{item.description || ''}</Text>
              </View>
              <View style={stylesTemplate1.tableCol}>
                <Text style={stylesTemplate1.tableCell}>{item.quantity || 0}</Text>
              </View>
              <View style={stylesTemplate1.tableCol}>
                <Text style={stylesTemplate1.tableCell}>₡{item.unitPrice.toFixed(2)}</Text>
              </View>
              <View style={stylesTemplate1.tableCol}>
                <Text style={stylesTemplate1.tableCell}>₡{item.total.toFixed(2)}</Text>
              </View>
            </View>
          ))}
        </View>
        <Text style={stylesTemplate1.total}>Subtotal: ₡{(total - tax).toFixed(2)}</Text>
        <Text style={stylesTemplate1.total}>Impuesto: ₡{formattedTax}</Text>
        <Text style={stylesTemplate1.total}>Total: ₡{formattedTotal}</Text>
      </Page>
    </Document>
  );
};


export const InvoiceTemplate2 = ({ invoice = {}, logoUrl }) => {
  const { client = {}, items = [], invoiceNumber = '', date = '', total = 0, tax = 0 } = invoice;
  const { name = 'N/A', address = 'N/A', email = 'N/A', phone = 'N/A' } = client || {};

  const formattedTotal = typeof total === 'number' ? total.toFixed(2) : '0.00';
  const formattedTax = typeof tax === 'number' ? tax.toFixed(2) : '0.00';

  return (
    <Document>
      <Page style={stylesTemplate2.page}>
        <View style={stylesTemplate2.headerContainer}>
          <Text style={stylesTemplate2.header}>Factura</Text>
        </View>
        <View style={stylesTemplate2.section}>
          <Text>Nombre del Cliente: {name}</Text>
          <Text>Dirección: {address}</Text>
          <Text>Email: {email}</Text>
          <Text>Teléfono: {phone}</Text>
        </View>
        <View style={stylesTemplate2.section}>
          <Text>Número de Factura: {invoiceNumber}</Text>
          <Text>Fecha: {date}</Text>
        </View>
        <View style={stylesTemplate2.table}>
          <View style={[stylesTemplate2.tableRow, stylesTemplate2.tableHeader]}>
            <View style={stylesTemplate2.tableCol}>
              <Text style={stylesTemplate2.tableCell}>Descripción</Text>
            </View>
            <View style={stylesTemplate2.tableCol}>
              <Text style={stylesTemplate2.tableCell}>Cantidad</Text>
            </View>
            <View style={stylesTemplate2.tableCol}>
              <Text style={stylesTemplate2.tableCell}>Precio Unitario</Text>
            </View>
            <View style={stylesTemplate2.tableCol}>
              <Text style={stylesTemplate2.tableCell}>Total</Text>
            </View>
          </View>
          {items.map((item, index) => (
            <View key={index} style={stylesTemplate2.tableRow}>
              <View style={stylesTemplate2.tableCol}>
                <Text style={stylesTemplate2.tableCell}>{item.description || ''}</Text>
              </View>
              <View style={stylesTemplate2.tableCol}>
                <Text style={stylesTemplate2.tableCell}>{item.quantity || 0}</Text>
              </View>
              <View style={stylesTemplate2.tableCol}>
                <Text style={stylesTemplate2.tableCell}>₡{item.unitPrice.toFixed(2)}</Text>
              </View>
              <View style={stylesTemplate2.tableCol}>
                <Text style={stylesTemplate2.tableCell}>₡{item.total.toFixed(2)}</Text>
              </View>
            </View>
          ))}
        </View>
        <Text style={stylesTemplate2.total}>Subtotal: ₡{(total - tax).toFixed(2)}</Text>
        <Text style={stylesTemplate2.total}>Impuesto: ₡{formattedTax}</Text>
        <Text style={stylesTemplate2.total}>Total: ₡{formattedTotal}</Text>
      </Page>
    </Document>
  );
};

export const InvoiceTemplate3 = ({ invoice = {}, logoUrl }) => {
  const { client = {}, items = [], invoiceNumber = '', date = '', total = 0, tax = 0 } = invoice;
  const { name = 'N/A', address = 'N/A', email = 'N/A', phone = 'N/A' } = client || {};

  const formattedTotal = typeof total === 'number' ? total.toFixed(2) : '0.00';
  const formattedTax = typeof tax === 'number' ? tax.toFixed(2) : '0.00';

  return (
    <Document>
      <Page style={stylesTemplate3.page}>
        <View style={stylesTemplate3.headerContainer}>
          <Text style={stylesTemplate3.header}>Factura</Text>
        </View>
        <View style={stylesTemplate3.clientDetails}>
          <Text>Nombre del Cliente: {name}</Text>
          <Text>Dirección: {address}</Text>
          <Text>Email: {email}</Text>
          <Text>Teléfono: {phone}</Text>
        </View>
        <View style={stylesTemplate3.section}>
          <Text>Número de Factura: {invoiceNumber}</Text>
          <Text>Fecha: {date}</Text>
        </View>
        <View style={stylesTemplate3.table}>
          <View style={[stylesTemplate3.tableRow, stylesTemplate3.tableHeader]}>
            <View style={stylesTemplate3.tableCol}>
              <Text style={stylesTemplate3.tableCell}>Descripción</Text>
            </View>
            <View style={stylesTemplate3.tableCol}>
              <Text style={stylesTemplate3.tableCell}>Cantidad</Text>
            </View>
            <View style={stylesTemplate3.tableCol}>
              <Text style={stylesTemplate3.tableCell}>Precio Unitario</Text>
            </View>
            <View style={stylesTemplate3.tableCol}>
              <Text style={stylesTemplate3.tableCell}>Total</Text>
            </View>
          </View>
          {items.map((item, index) => (
            <View key={index} style={stylesTemplate3.tableRow}>
              <View style={stylesTemplate3.tableCol}>
                <Text style={stylesTemplate3.tableCell}>{item.description || ''}</Text>
              </View>
              <View style={stylesTemplate3.tableCol}>
                <Text style={stylesTemplate3.tableCell}>{item.quantity || 0}</Text>
              </View>
              <View style={stylesTemplate3.tableCol}>
                <Text style={stylesTemplate3.tableCell}>₡{item.unitPrice.toFixed(2)}</Text>
              </View>
              <View style={stylesTemplate3.tableCol}>
                <Text style={stylesTemplate3.tableCell}>₡{item.total.toFixed(2)}</Text>
              </View>
            </View>
          ))}
        </View>
        <Text style={stylesTemplate3.total}>Subtotal: ₡{(total - tax).toFixed(2)}</Text>
        <Text style={stylesTemplate3.total}>Impuesto: ₡{formattedTax}</Text>
        <Text style={stylesTemplate3.total}>Total: ₡{formattedTotal}</Text>
      </Page>
    </Document>
  );
};

