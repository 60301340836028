import React, { useState, useEffect } from "react";
import { FaFilter, FaPlus } from "react-icons/fa";
import useSocket from "../../hook/useSocket";
import useAxios from "../../hook/useAxios";
import toast from "react-hot-toast";
import Modal from "../../Components/Dashboard/Modal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { NavLink } from "react-router-dom";
import axios from "axios";

const Sales = () => {
  const [sales, setSales] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSale, setSelectedSale] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    recent: true,
    highest: false,
    clientName: false,
    dateRange: false,
  });
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  const [clientName, setClientName] = useState("");
  const [cutBoxModalOpen, setCutBoxModalOpen] = useState(false);
  const token = localStorage.getItem("token");
  const socket = useSocket(token);

  const fetchSales = useAxios(
    {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/api/sales`,
      headers: { Authorization: `Bearer ${token}` },
    },
    (data) => {
      setSales(
        data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      );
      setLoading(false);
    },
    (error) => {
      if (error.response && error.response.status !== 401) {
        console.error("Error fetching sales:", error);
        toast.error("Error fetching sales");
        setLoading(false);
      }
    }
  );

  const handleCutBox = useAxios(
    {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/cash/cutbox`,
      headers: { Authorization: `Bearer ${token}` },
    },
    (data) => {
      toast.success("Corte de Caja realizado exitosamente");
      setCutBoxModalOpen(false);
    },
    (error) => {
      if (error.response.status === 400) {
        toast.error(
          "Necesitas al menos una venta para realizar el corte de caja"
        );
      } else {
        toast.error("Error realizando el corte de caja");
      }
      setCutBoxModalOpen(false);
    }
  );

  useEffect(() => {
    fetchSales.execute();
  }, [token]);

  useEffect(() => {
    if (socket) {
      socket.on("sale-added", (newSale) => {
        setSales((prevSales) => [newSale, ...prevSales]);
      });
    }

    return () => {
      if (socket) {
        socket.off("sale-added");
      }
    };
  }, [socket]);

  const handleDateRangeChange = (e) => {
    const { name, value } = e.target;
    setDateRange((prevRange) => ({
      ...prevRange,
      [name]: value,
    }));
  };

  const filteredSales = sales
    .filter((sale) => {
      let matchesSearchTerm =
        sale.salenum.toString().includes(searchTerm) ||
        sale.total.toString().includes(searchTerm) ||
        (sale.clientId &&
          sale.clientId.name &&
          sale.clientId.name.toLowerCase().includes(searchTerm.toLowerCase()));

      if (filterOptions.dateRange && dateRange.startDate && dateRange.endDate) {
        const saleDate = new Date(sale.createdAt);
        const startDate = new Date(dateRange.startDate);
        const endDate = new Date(dateRange.endDate);
        matchesSearchTerm =
          matchesSearchTerm && saleDate >= startDate && saleDate <= endDate;
      }

      if (filterOptions.clientName && clientName) {
        matchesSearchTerm =
          matchesSearchTerm &&
          sale.clientId &&
          sale.clientId.name &&
          sale.clientId.name.toLowerCase().includes(clientName.toLowerCase());
      }

      return matchesSearchTerm;
    })
    .sort((a, b) => {
      if (filterOptions.recent) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }
      if (filterOptions.highest) {
        return b.total - a.total;
      }
      if (filterOptions.clientName) {
        if (a.clientId && b.clientId) {
          return a.clientId.name.localeCompare(b.clientId.name);
        }
        return 0;
      }
      return 0;
    });

  const handleSaleClick = (sale) => {
    setSelectedSale(sale);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedSale(null);
  };

  const handleFilterOptionChange = (option) => {
    setFilterOptions({
      ...filterOptions,
      [option]: !filterOptions[option],
    });
  };

  const openCutBoxModal = () => {
    setCutBoxModalOpen(true);
  };

  const closeCutBoxModal = () => {
    setCutBoxModalOpen(false);
  };

  const confirmCutBox = () => {
    handleCutBox.execute();
  };

  const handleQuantityChange = (index, newQuantity) => {
    const updatedSale = { ...selectedSale };
    const item = updatedSale.cartItems[index];
    item.quantity = newQuantity;
    item.subtotal = item.price * newQuantity * (1 - item.discount / 100);
    updatedSale.total = updatedSale.cartItems.reduce(
      (total, item) => total + item.subtotal,
      0
    );
    setSelectedSale(updatedSale);
  };

  const saveChanges = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/update/${selectedSale._id}`,
        selectedSale,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Venta actualizada exitosamente.");
      fetchSales.execute();
      closeModal();
    } catch (error) {
      console.error("Error saving changes:", error);
      toast.error("Error al guardar los cambios.");
    }
  };

  return (
    <div className="md:ml-[256px] p-8 min-h-screen flex flex-col overflow-hidden bg-gray-900 text-white">
      <div className="mb-4 flex justify-between items-center">
        <h1 className="text-xl md:text-2xl font-semibold">Ventas</h1>
        <div>
          <button
            className="hidden md:inline bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
            onClick={() => setFilterMenuOpen(!filterMenuOpen)}
          >
            Filtrar
          </button>
          <NavLink
            to="/dashboard/new-sale"
            className="hidden md:inline bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            Nueva Venta
          </NavLink>
          <button
            className="md:hidden text-gray-500 hover:text-gray-700 mr-2"
            onClick={() => setFilterMenuOpen(!filterMenuOpen)}
          >
            <FaFilter />
          </button>
          <button className="md:hidden text-gray-500 hover:text-gray-700">
            <FaPlus />
          </button>
        </div>
      </div>

      {filterMenuOpen && (
        <div className="mb-4 bg-gray-800 shadow rounded p-4">
          <h2 className="text-lg font-semibold mb-2">Opciones de Filtrado</h2>
          <div className="space-y-2">
            <label className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={filterOptions.recent}
                onChange={() => handleFilterOptionChange("recent")}
              />
              <span className="ml-2">Más recientes</span>
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={filterOptions.highest}
                onChange={() => handleFilterOptionChange("highest")}
              />
              <span className="ml-2">Mayor total</span>
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={filterOptions.clientName}
                onChange={() => handleFilterOptionChange("clientName")}
              />
              <span className="ml-2">Nombre del cliente</span>
            </label>
            {filterOptions.clientName && (
              <>
                <input
                  type="text"
                  className="mt-1 block w-full border border-gray-600 rounded-md shadow-sm p-2 bg-gray-700 text-gray-300"
                  placeholder="Buscar por nombre de cliente"
                  value={clientName}
                  onChange={(e) => setClientName(e.target.value)}
                />
              </>
            )}
            <label className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={filterOptions.dateRange}
                onChange={() => handleFilterOptionChange("dateRange")}
              />
              <span className="ml-2">Rango de fechas</span>
            </label>
            {filterOptions.dateRange && (
              <div className="flex flex-col space-y-2 mt-2">
                <label className="flex flex-col">
                  <span className="text-sm">Fecha de inicio:</span>
                  <input
                    type="date"
                    name="startDate"
                    value={dateRange.startDate}
                    onChange={handleDateRangeChange}
                    className="mt-1 block w-full border border-gray-600 rounded-md shadow-sm p-2 bg-gray-700 text-gray-300"
                  />
                </label>
                <label className="flex flex-col">
                  <span className="text-sm">Fecha de fin:</span>
                  <input
                    type="date"
                    name="endDate"
                    value={dateRange.endDate}
                    onChange={handleDateRangeChange}
                    className="mt-1 block w-full border border-gray-600 rounded-md shadow-sm p-2 bg-gray-700 text-gray-300"
                  />
                </label>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="flex items-center mb-4">
        <input
          className="flex-grow shadow appearance-none border border-gray-600 rounded py-2 px-3 text-gray-300 bg-gray-800 leading-tight focus:outline-none focus:shadow-outline"
          id="search"
          type="text"
          placeholder="Buscar ventas"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {loading
          ? Array.from({ length: 6 }).map((_, index) => (
              <div key={index} className="bg-gray-800 shadow rounded p-4">
                <Skeleton height={64} width={64} />
                <div className="mt-4">
                  <Skeleton height={20} width="80%" />
                  <Skeleton height={20} width="60%" />
                  <Skeleton height={20} width="40%" />
                </div>
              </div>
            ))
          : filteredSales.map((sale) => (
              <div
                key={sale._id}
                className="bg-gray-800 shadow rounded p-4 cursor-pointer"
                onClick={() => handleSaleClick(sale)}
              >
                <div className="flex flex-col space-y-2">
                  <h3 className="text-lg font-semibold text-white">
                    Venta #{sale.salenum}
                  </h3>
                  <p className="text-sm text-gray-400">
                    Fecha: {new Date(sale.createdAt).toLocaleDateString()}
                  </p>
                  <p className="text-sm text-gray-400">
                    Cliente: {sale.clientId?.name || "N/A"}
                  </p>
                  <p className="text-lg text-purple-400">
                    Total: ₡{sale.total.toFixed(2)}
                  </p>
                </div>
              </div>
            ))}
      </div>

      {modalOpen && selectedSale && (
        <Modal onClose={closeModal}>
          <div className="p-4">
            <h2 className="text-2xl font-bold mb-4 text-white">
              Detalles de la Venta #{selectedSale.salenum}
            </h2>
            <div className="mb-4 text-gray-300">
              <p>
                <strong>Fecha:</strong>{" "}
                {new Date(selectedSale.createdAt).toLocaleDateString()}
              </p>
              <p>
                <strong>Cliente:</strong> {selectedSale.clientId?.name || "N/A"}
              </p>
              <p>
                <strong>Método de Pago:</strong> {selectedSale.paymentMethod || "N/A"}
              </p>
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-gray-800 border border-gray-700">
                <thead>
                  <tr>
                    <th className="py-2 px-4 border-b text-left text-gray-300">
                      Producto
                    </th>
                    <th className="py-2 px-4 border-b text-left text-gray-300">
                      Cantidad
                    </th>
                    <th className="py-2 px-4 border-b text-left text-gray-300">
                      Precio
                    </th>
                    <th className="py-2 px-4 border-b text-left text-gray-300">
                      Descuento
                    </th>
                    <th className="py-2 px-4 border-b text-left text-gray-300">
                      Subtotal
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {selectedSale.cartItems.map((item, index) => (
                    <tr key={index} className="border-b border-gray-700">
                      <td className="py-2 px-4 text-gray-300">{item.name}</td>
                      <td className="py-2 px-4 text-gray-300">
                        <input
                          type="number"
                          className="w-16 text-center border rounded bg-gray-700 text-gray-300"
                          value={item.quantity}
                          onChange={(e) =>
                            handleQuantityChange(index, e.target.value)
                          }
                        />
                      </td>
                      <td className="py-2 px-4 text-gray-300">
                        ₡{item.price.toFixed(2)}
                      </td>
                      <td className="py-2 px-4 text-gray-300">
                        {item.discount > 0 ? `${item.discount}%` : "-"}
                      </td>
                      <td className="py-2 px-4 text-gray-300">
                        ₡
                        {(
                          item.price *
                          item.quantity *
                          (1 - item.discount / 100)
                        ).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mt-4 flex justify-between items-center text-gray-300">
              <p>
                <strong>Total:</strong> ₡{selectedSale.total.toFixed(2)}
              </p>
              <p>
                <strong>Último Cambio por:</strong>{" "}
                {selectedSale.lastModifiedBy
                  ? selectedSale.lastModifiedBy.name
                  : "Sin cambios"}
              </p>
              <button
                onClick={saveChanges}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
              >
                Guardar Cambios
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Sales;
