import React from 'react';
import ReactDOM from 'react-dom';

const CalendarModal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-gray-800 text-white p-6 rounded shadow-lg relative w-full max-w-md mx-4">
                <button
                    className="absolute top-0 right-0 mt-2 mr-2 text-gray-400 hover:text-gray-600"
                    onClick={onClose}
                >
                    &times;
                </button>
                {children}
            </div>
        </div>,
        document.getElementById('modal-root')
    );
};

export default CalendarModal;
