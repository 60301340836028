import React, { useEffect } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import logo from '../../assets/favicon.png';
import useAxios from '../../hook/useAxios'; // Asegúrate de ajustar la ruta según la estructura de tu proyecto

const NavBar = () => {
    const { data: userData, execute: fetchUserData } = useAxios(
        {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/api/users/me`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        },
        (data) => {
        },
        (error) => {
            console.error('Error fetching user data:', error);
        }
    );

    useEffect(() => {
        fetchUserData();
    }, []); 

    return (
        <nav className="bg-gray-800 text-white shadow-md w-full fixed top-0 z-20">
            <div className="flex justify-between items-center h-16 px-4 sm:px-6 lg:px-8">
                <div className="flex-shrink-0">
                    <img src={logo} alt="Logo" className="h-12 w-12 md:h-16 md:w-16" />
                </div>
                <div className="flex items-center">
                    <span className="mr-2">
                        {userData ? userData.name : 'Cargando...'}
                    </span>
                    <button className="focus:outline-none">
                        <FaUserCircle className="w-8 h-8" />
                    </button>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
