import React, { useEffect, useState } from 'react';
import { FaFilter, FaImage, FaPlus, FaEdit, FaTrash } from 'react-icons/fa';
import Modal from '../../Components/Dashboard/Modal';
import useAxios from '../../hook/useAxios';
import useSocket from '../../hook/useSocket';
import toast from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Products = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [productData, setProductData] = useState({
        name: '',
        quantity: '',
        price: '',
        discount: 0,
        useDiscount: false,
        applyTax: false,
        tax: 0,
        brand: '',
        includeImage: false,
        includeBrand: false,
        includeSize: false,
        size: '',
        sizeType: '', // New state for size type (number or letter)
        image: null // Estado para la imagen
    });

    const [editMode, setEditMode] = useState(false);
    const [currentProductId, setCurrentProductId] = useState(null);
    const token = localStorage.getItem('token');
    const socket = useSocket(token);

    const fetchProducts = useAxios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/products`,
        headers: { Authorization: `Bearer ${token}` }
    }, (data) => {
        setProducts(data);
        setLoading(false);
    }, (error) => {
        if (error.response && error.response.status !== 401) {
            console.error('Error fetching products:', error);
            toast.error('Error fetching products');
            setLoading(false);
        }
    });

    useEffect(() => {
        fetchProducts.execute();
    }, []);

    useEffect(() => {
        if (socket) {
            socket.on('product-added', (product) => {
                setProducts(prevProducts => [...prevProducts, product]);
            });

            socket.on('product-updated', (updatedProduct) => {
                setProducts(prevProducts => prevProducts.map(product =>
                    product._id === updatedProduct._id ? updatedProduct : product
                ));
            });

            socket.on('product-deleted', (productId) => {
                setProducts(prevProducts => prevProducts.filter(product => product._id !== productId));
            });
        }
    }, [socket]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setProductData({
            ...productData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleImageChange = (e) => {
        setProductData({
            ...productData,
            image: e.target.files[0]
        });
    };

    const openModal = () => setModalOpen(true);
    const closeModal = () => {
        setModalOpen(false);
        setEditMode(false);
        setProductData({
            name: '',
            quantity: '',
            price: '',
            discount: 0,
            useDiscount: false,
            applyTax: false,
            tax: 0,
            brand: '',
            includeImage: false,
            includeBrand: false,
            includeSize: false,
            size: '',
            sizeType: '',
            image: null
        });
    };

    const uploadImage = async () => {
        if (!productData.image) return null;

        const formData = new FormData();
        formData.append('image', productData.image);

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/products/uploadImage`, {
            method: 'POST',
            headers: { Authorization: `Bearer ${token}` },
            body: formData
        });

        const data = await response.json();
        if (response.ok) {
            return data.fileUrl;
        } else {
            toast.error('Error al subir la imagen');
            return null;
        }
    };

    const saveProduct = useAxios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/products/add`,
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        data: productData
    }, (data) => {
        toast.success('Producto agregado exitosamente');
        socket.emit('product-added', data);
        closeModal();
    }, (error) => {
        toast.error('Error al agregar producto');
        console.error('Error adding product:', error);
    });

    const updateProduct = useAxios({
        method: 'put',
        url: `${process.env.REACT_APP_API_URL}/api/products/update/${currentProductId}`,
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        data: productData
    }, (data) => {
        toast.success('Producto actualizado exitosamente');
        socket.emit('product-updated', data);
        setProducts(prevProducts => prevProducts.map(product => product._id === data._id ? data : product));
        closeModal();
    }, (error) => {
        toast.error('Error al actualizar producto');
        console.error('Error updating product:', error);
    });

    const deleteProduct = useAxios({
        method: 'delete',
        url: `${process.env.REACT_APP_API_URL}/api/products/delete/${currentProductId}`,
        headers: { Authorization: `Bearer ${token}` }
    }, () => {
        toast.success('Producto eliminado exitosamente');
        setProducts(prevProducts => prevProducts.filter(p => p._id !== currentProductId));
        socket.emit('product-deleted', currentProductId);
        setConfirmDeleteModalOpen(false); // Close the confirmation modal
    }, (error) => {
        toast.error('Error al eliminar producto');
        console.error('Error deleting product:', error);
    });

    const handleSaveProduct = async () => {
        setLoading(true);

        let imageUrl = productData.fileUrl;

        if (productData.includeImage && productData.image) {
            imageUrl = await uploadImage();
            if (!imageUrl) {
                setLoading(false);
                return;
            }
        }

        const productToSave = { ...productData, fileUrl: imageUrl };

        if (editMode) {
            await updateProduct.execute({ data: productToSave });
        } else {
            await saveProduct.execute({ data: productToSave });
        }

        setLoading(false);
    };

    const handleEditProduct = (product) => {
        setProductData({
            name: product.name || '',
            quantity: product.quantity || '',
            price: product.price || '',
            discount: product.discount || 0,
            useDiscount: product.useDiscount || false,
            applyTax: product.applyTax || false,
            tax: product.tax || 0,
            brand: product.brand || '',
            includeImage: product.includeImage || false,
            includeBrand: !!product.brand,
            includeSize: !!product.size,
            size: product.size || '',
            sizeType: isNaN(product.size) ? 'letter' : 'number',
            image: null,
            fileUrl: product.imageUrl // Aseguramos que la URL de la imagen actual se mantenga
        });
        setCurrentProductId(product._id);
        setEditMode(true);
        openModal();
    };

    const handleDeleteProduct = (productId) => {
        setCurrentProductId(productId);
        setConfirmDeleteModalOpen(true);
    };

    const confirmDeleteProduct = () => {
        deleteProduct.execute({ url: `${process.env.REACT_APP_API_URL}/api/products/delete/${currentProductId}` });
    };

    const filteredProducts = products.filter(product => 
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="md:ml-[256px] p-8 min-h-screen flex flex-col bg-gray-900 text-white overflow-hidden">
            <div className="mb-4 flex justify-between items-center">
                <h1 className="text-xl md:text-2xl font-semibold">Productos</h1>
                <div>
                    <button className="hidden md:inline bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2">
                        Filtrar
                    </button>
                    <button onClick={openModal} className="hidden md:inline bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                        Agregar Producto
                    </button>
                    {modalOpen && (
                        <Modal onClose={closeModal}>
                            <form className="space-y-4 mt-4">
                                <input
                                    className="shadow-sm border-gray-300 rounded-lg py-2 px-3 w-full bg-gray-800 text-white focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Nombre del producto"
                                    name="name"
                                    value={productData.name}
                                    onChange={handleInputChange}
                                />
                                <div className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        className="rounded text-blue-500 focus:ring-blue-500"
                                        name="includeBrand"
                                        checked={productData.includeBrand}
                                        onChange={handleInputChange}
                                    />
                                    <span className="text-gray-300">Incluir Marca</span>
                                </div>
                                {productData.includeBrand && (
                                    <div>
                                        <input
                                            className="shadow-sm border-gray-300 rounded-lg py-2 px-3 w-full bg-gray-800 text-white focus:ring-blue-500 focus:border-blue-500"
                                            placeholder="Marca"
                                            name="brand"
                                            type="text"
                                            value={productData.brand}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                )}
                                <input
                                    className="shadow-sm border-gray-300 rounded-lg py-2 px-3 w-full bg-gray-800 text-white focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Cantidad"
                                    name="quantity"
                                    type="number"
                                    value={productData.quantity}
                                    onChange={handleInputChange}
                                />
                                
                                <input
                                    className="shadow-sm border-gray-300 rounded-lg py-2 px-3 w-full bg-gray-800 text-white focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="¢ Precio"
                                    name="price"
                                    type="number"
                                    value={productData.price}
                                    onChange={handleInputChange}
                                />
                                <div className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        className="rounded text-blue-500 focus:ring-blue-500"
                                        name="useDiscount"
                                        checked={productData.useDiscount}
                                        onChange={handleInputChange}
                                    />
                                    <span className="text-gray-300">Usar descuento</span>
                                </div>
                                {productData.useDiscount && (
                                    <div className="flex items-center space-x-2">
                                        <input
                                            className="shadow-sm border-gray-300 rounded-lg py-2 px-3 w-full bg-gray-800 text-white focus:ring-blue-500 focus:border-blue-500"
                                            placeholder="Descuento"
                                            name="discount"
                                            type="number"
                                            value={productData.discount}
                                            onChange={handleInputChange}
                                        />
                                        <span className="text-gray-300">%</span>
                                    </div>
                                )}
                                <div className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        className="rounded text-blue-500 focus:ring-blue-500"
                                        name="applyTax"
                                        checked={productData.applyTax}
                                        onChange={handleInputChange}
                                    />
                                    <span className="text-gray-300">Aplicar Impuesto</span>
                                </div>
                                {productData.applyTax && (
                                    <div className="flex items-center space-x-2">
                                        <input
                                            className="shadow-sm border-gray-300 rounded-lg py-2 px-3 w-full bg-gray-800 text-white focus:ring-blue-500 focus:border-blue-500"
                                            placeholder="Impuesto"
                                            name="tax"
                                            type="number"
                                            value={productData.tax}
                                            onChange={handleInputChange}
                                        />
                                        <span className="text-gray-300">%</span>
                                    </div>
                                )}
                                <div className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        className="rounded text-blue-500 focus:ring-blue-500"
                                        name="includeImage"
                                        checked={productData.includeImage}
                                        onChange={handleInputChange}
                                    />
                                    <span className="text-gray-300">Incluir imagen</span>
                                </div>
                                {productData.includeImage && (
                                    <div>
                                        <label htmlFor="image" className="text-gray-300">Imagen:</label>
                                        <input
                                            type="file"
                                            id="image"
                                            name="image"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            className="bg-gray-800 text-white"
                                        />
                                    </div>
                                )}
                                <div className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        className="rounded text-blue-500 focus:ring-blue-500"
                                        name="includeSize"
                                        checked={productData.includeSize}
                                        onChange={handleInputChange}
                                    />
                                    <span className="text-gray-300">Incluir Talla</span>
                                </div>
                                {productData.includeSize && (
                                    <>
                                        <div className="flex items-center space-x-2">
                                            <input
                                                type="radio"
                                                name="sizeType"
                                                value="number"
                                                checked={productData.sizeType === 'number'}
                                                onChange={handleInputChange}
                                                className="text-blue-500 focus:ring-blue-500"
                                            />
                                            <span className="text-gray-300">Número</span>
                                            <input
                                                type="radio"
                                                name="sizeType"
                                                value="letter"
                                                checked={productData.sizeType === 'letter'}
                                                onChange={handleInputChange}
                                                className="text-blue-500 focus:ring-blue-500"
                                            />
                                            <span className="text-gray-300">Letra</span>
                                        </div>
                                        {productData.sizeType === 'number' ? (
                                            <input
                                                className="shadow-sm border-gray-300 rounded-lg py-2 px-3 w-full bg-gray-800 text-white focus:ring-blue-500 focus:border-blue-500"
                                                placeholder="Talla (Número)"
                                                name="size"
                                                type="number"
                                                value={productData.size}
                                                onChange={handleInputChange}
                                            />
                                        ) : (
                                            <select
                                                className="shadow-sm border-gray-300 rounded-lg py-2 px-3 w-full bg-gray-800 text-white focus:ring-blue-500 focus:border-blue-500"
                                                name="size"
                                                value={productData.size}
                                                onChange={handleInputChange}
                                            >
                                                <option value="">Seleccione una talla</option>
                                                <option value="S">S</option>
                                                <option value="M">M</option>
                                                <option value="L">L</option>
                                                <option value="XL">XL</option>
                                            </select>
                                        )}
                                    </>
                                )}

                                <button
                                    type="button"
                                    className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
                                    onClick={handleSaveProduct}
                                >
                                    {editMode ? 'Actualizar' : 'Guardar'}
                                </button>
                            </form>
                        </Modal>
                    )}
                    {confirmDeleteModalOpen && (
                        <Modal onClose={() => setConfirmDeleteModalOpen(false)}>
                            <div className="p-4">
                                <h2 className="text-xl mb-4">Confirmar Eliminación</h2>
                                <p>¿Estás seguro de que deseas eliminar este producto?</p>
                                <div className="flex justify-end space-x-4 mt-4">
                                    <button
                                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                        onClick={confirmDeleteProduct}
                                    >
                                        Eliminar
                                    </button>
                                    <button
                                        className="bg-gray-300 hover:bg-gray-500 text-black font-bold py-2 px-4 rounded"
                                        onClick={() => setConfirmDeleteModalOpen(false)}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </Modal>
                    )}
                    <button className="md:hidden text-gray-500 hover:text-gray-700 mr-2">
                        <FaFilter />
                    </button>
                    <button onClick={openModal} className="md:hidden text-gray-500 hover:text-gray-700">
                        <FaPlus />
                    </button>
                </div>
            </div>
            <div className="flex items-center mb-4">
                <input
                    className="flex-grow shadow appearance-none border rounded py-2 px-3 bg-gray-800 text-white leading-tight focus:outline-none focus:shadow-outline"
                    id="search"
                    type="text"
                    placeholder="Buscar productos"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {loading ? (
                    Array.from({ length: 5 }).map((_, index) => (
                        <div key={index} className="bg-gray-800 shadow rounded p-4">
                            <Skeleton height={64} width={64} />
                            <div className="mt-4">
                                <Skeleton height={20} width="80%" />
                                <Skeleton height={20} width="60%" />
                                <Skeleton height={20} width="40%" />
                            </div>
                        </div>
                    ))
                ) : (
                    filteredProducts.map((product) => (
                        <div key={product._id} className="bg-gray-800 shadow rounded p-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0">
                                    {product.imageUrl ? (
                                        <img
                                            src={product.imageUrl}
                                            alt={product.name}
                                            className="h-16 w-16 object-cover rounded"
                                            onError={(e) => {
                                                e.target.onerror = null; // Previene loops en caso de que la imagen de respaldo falle
                                                e.target.style.display = 'none'; // Oculta la imagen si falla la carga
                                                e.target.nextSibling.style.display = 'block'; // Muestra el ícono
                                            }}
                                        />
                                    ) : (
                                        <div className="h-16 w-16 flex items-center justify-center bg-gray-700 rounded">
                                            <FaImage className="text-gray-400" size="2em" />
                                        </div>
                                    )}
                                    <FaImage
                                        className="h-16 w-16 text-gray-400"
                                        size="2em"
                                        style={{ display: 'none' }} // Oculto por defecto
                                    />
                                </div>
                                <div className="flex-grow">
                                    <h3 className="text-lg font-semibold text-white">{product.name}</h3>
                                    <p className="text-sm text-gray-400">{product.quantity} unidades</p>
                                    <p className="text-sm text-gray-400">{product.price} colones</p>
                                    <p className="text-sm text-gray-400">{product.brand}</p>
                                    {product.size && <p className="text-sm text-gray-400">Talla: {product.size}</p>}
                                    <div className="flex space-x-2 mt-2">
                                        <button
                                            className="text-blue-500 hover:text-blue-700"
                                            onClick={() => handleEditProduct(product)}
                                        >
                                            <FaEdit />
                                        </button>
                                        <button
                                            className="text-red-500 hover:text-red-700"
                                            onClick={() => handleDeleteProduct(product._id)}
                                        >
                                            <FaTrash />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default Products;
