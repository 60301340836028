import React, { useState, useEffect } from 'react';
import useAxios from '../../hook/useAxios';
import toast from 'react-hot-toast';

const Settings = () => {
    const [companyName, setCompanyName] = useState('');
    const [enableInvoices, setEnableInvoices] = useState(false);
    const [taxRate, setTaxRate] = useState(0);
    const [currency, setCurrency] = useState('USD');
    const [notifications, setNotifications] = useState(false);
    const [logo, setLogo] = useState(null);
    const token = localStorage.getItem('token');
    const plan = localStorage.getItem('plan');

    const { data: settingsData, execute: fetchSettings } = useAxios(
        {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/api/settings`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        },
        (data) => {
            setCompanyName(data.companyName || '');
            setEnableInvoices(data.enableInvoices || false);
            setTaxRate(data.taxRate || 0);
            setCurrency(data.currency || 'USD');
            setNotifications(data.notifications || false);
            setLogo(data.logoUrl || null);
        },
        (error) => {
            console.error('Error fetching settings:', error);
        }
    );

    useEffect(() => {
        if (token) {
            fetchSettings();
        }
    }, [token]);

    useEffect(() => {
        document.title = companyName ? `POS | ${companyName}` : 'POS';
    }, [companyName]);

    const { execute: updateSettings } = useAxios(
        {
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}/api/settings`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            data: {
                companyName,
                enableInvoices,
                taxRate,
                currency,
                notifications,
            },
        },
        (data) => {
            toast.success('Configuraciones guardadas exitosamente.');
            setCompanyName(data.companyName || '');
        },
        (error) => {
            console.error('Error updating settings:', error);
        }
    );

    const handleSaveSettings = () => {
        updateSettings();
    };

    const handleLogoChange = (event) => {
        setLogo(event.target.files[0]);
    };

    const handleLogoUpload = async () => {
        if (!logo) {
            toast.error('Por favor selecciona una imagen.');
            return;
        }

        const formData = new FormData();
        formData.append('image', logo);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/uploadLogo`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Error al subir el logo.');
            }

            const data = await response.json();
            toast.success('Logo subido exitosamente.');
            setLogo(data.fileUrl || null);
        } catch (error) {
            console.error('Error uploading logo:', error);
            toast.error('Error al subir el logo.');
        }
    };

    return (
        <div className="container  text-white min-h-screen">
            <div className="md:ml-[256px] p-8 flex flex-col overflow-hidden">
                <h1 className="text-2xl font-semibold mb-6">Configuración</h1>
                
                {(plan === 'Standard' || plan === 'Premium') && (
                    <>
                        <div className="max-w-md mb-8">
                            <h2 className="text-lg font-semibold mb-2">Nombre de la Empresa</h2>
                            <input
                                id="companyName"
                                type="text"
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-400 rounded-md px-4 py-2 dark:bg-gray-800 dark:border-gray-500 dark:text-gray-300"
                                placeholder="Ingrese el nombre de la empresa"
                            />
                        </div>
                        <div className="max-w-md mb-8">
                            <h2 className="text-lg font-semibold mb-2">Logo de la Empresa</h2>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleLogoChange}
                                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-400 rounded-md px-4 py-2 dark:bg-gray-800 dark:border-gray-500 dark:text-gray-300"
                            />
                            <button
                                onClick={handleLogoUpload}
                                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded mt-2"
                            >
                                Subir Logo
                            </button>
                        </div>
                    </>
                )}

                <div className="max-w-md mb-8">
                    <h2 className="text-lg font-semibold mb-2">Impuestos</h2>
                    <input
                        id="taxRate"
                        type="number"
                        value={taxRate}
                        onChange={(e) => setTaxRate(Number(e.target.value))}
                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-400 rounded-md px-4 py-2 dark:bg-gray-800 dark:border-gray-500 dark:text-gray-300"
                        placeholder="Ingrese el porcentaje de impuesto sobre las ventas"
                    />
                </div>
                
                <div className="max-w-md mb-8">
                    <button
                        onClick={handleSaveSettings}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                    >
                        Guardar Configuración
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Settings;
