import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  FaPlusCircle,
  FaRegClipboard,
  FaBoxOpen,
  FaSearch,
  FaTruck,
  FaHeadset,
  FaHome,
  FaShoppingBasket,
  FaTags,
  FaBars,
  FaCalendarAlt,
  FaUsers,
  FaUserTie,
  FaCashRegister,
  FaKey,
} from "react-icons/fa";
import { IoSettingsSharp } from "react-icons/io5";
import { MdExitToApp } from "react-icons/md";
import logo from "../../assets/favicon.png";
import { toast } from "react-hot-toast";
import axios from "axios";

const SideBar = () => {
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(logo);
  const [companyName, setCompanyName] = useState("POSAUR");
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const plan = localStorage.getItem("plan");

  useEffect(() => {
    const fetchCompanySettings = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/settings`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.logoUrl) {
          setCompanyLogo(response.data.logoUrl);
        }
        if (response.data.companyName) {
          setCompanyName(response.data.companyName);
        }
      } catch (error) {
        console.error("Error fetching company settings:", error);
      }
    };

    fetchCompanySettings();
  }, []);

  const toggleMoreMenu = () => {
    setShowMoreMenu(!showMoreMenu);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("plan");
    toast.success("Sesión cerrada con éxito");
    navigate("/", { replace: true });
  };

  return (
    <>
      <aside className="w-64 bg-gray-800 text-gray-200 fixed inset-y-0 left-0 z-30 transform -translate-x-full md:translate-x-0 transition duration-200 ease-in-out overflow-y-auto">
        <div className="flex flex-col h-full">
          <div className="flex flex-col items-center py-6">
            <img src={companyLogo} alt="Logo" className="h-16 w-16" />
            <span className="text-xl font-semibold mt-3">{companyName}</span>
          </div>
          <div className="px-4 flex-grow">
            <NavLink
              to="/dashboard/new-sale"
              className={`w-full bg-blue-600 hover:bg-blue-700 text-white py-2 rounded-md transition-colors duration-150 flex items-center justify-center`}
            >
              <FaPlusCircle className="inline mr-2" /> Nueva Venta
            </NavLink>
            <nav className="mt-4 space-y-2">
              <NavLink
                to="/dashboard"
                className="flex items-center py-2 px-4 rounded-md hover:bg-blue-600 transition-colors duration-150"
              >
                <FaRegClipboard className="mr-2" /> Inicio
              </NavLink>
              <NavLink
                to="/dashboard/products"
                className="flex items-center py-2 px-4 rounded-md hover:bg-blue-600 transition-colors duration-150"
              >
                <FaBoxOpen className="mr-2" /> Productos
              </NavLink>
              <NavLink
                to="/dashboard/sales"
                className="flex items-center py-2 px-4 rounded-md hover:bg-blue-600 transition-colors duration-150"
              >
                <FaSearch className="mr-2" /> Ventas
              </NavLink>
              {plan !== "Free" && (
                <>
                  <NavLink
                    to="/dashboard/clients"
                    className="flex items-center py-2 px-4 rounded-md hover:bg-blue-600 transition-colors duration-150"
                  >
                    <FaUsers className="mr-2" /> Clientes
                  </NavLink>
                  {plan !== "Basic" && (
                    <>
                      <NavLink
                        to="/dashboard/calendar"
                        className="flex items-center py-2 px-4 rounded-md hover:bg-blue-600 transition-colors duration-150"
                      >
                        <FaCalendarAlt className="mr-2" /> Calendario
                      </NavLink>
                      <NavLink
                        to="/dashboard/cashbox"
                        className="flex items-center py-2 px-4 rounded-md hover:bg-blue-600 transition-colors duration-150"
                      >
                        <FaCashRegister className="mr-2" /> Corte de Caja
                      </NavLink>
                      <NavLink
                        to="/dashboard/suppliers"
                        className="flex items-center py-2 px-4 rounded-md hover:bg-blue-600 transition-colors duration-150"
                      >
                        <FaTruck className="mr-2" /> Proveedores
                      </NavLink>
                      {plan === "Premium" && (
                        <>
                          <NavLink
                            to="/dashboard/billing"
                            className="flex items-center py-2 px-4 rounded-md hover:bg-blue-600 transition-colors duration-150"
                          >
                            <FaRegClipboard className="mr-2" /> Facturación
                          </NavLink>
                          <NavLink
                            to="/dashboard/team"
                            className="flex items-center py-2 px-4 rounded-md hover:bg-blue-600 transition-colors duration-150"
                          >
                            <FaUserTie className="mr-2" /> Equipo
                          </NavLink>
                        </>
                      )}
                    </>
                  )}
                  <NavLink
                    to="/dashboard/license"
                    className="flex items-center py-2 px-4 rounded-md hover:bg-blue-600 transition-colors duration-150"
                  >
                    <FaKey className="mr-2" /> Licencia
                  </NavLink>
                </>
              )}
              <NavLink
                to="/dashboard/settings"
                className="flex items-center py-2 px-4 rounded-md hover:bg-blue-600 transition-colors duration-150"
              >
                <IoSettingsSharp className="mr-2" /> Configuración
              </NavLink>
              <NavLink
                to="/dashboard/support"
                className="flex items-center py-2 px-4 rounded-md hover:bg-blue-600 transition-colors duration-150"
              >
                <FaHeadset className="mr-2" /> Soporte
              </NavLink>
            </nav>
          </div>
          <div className="px-4 py-6">
            <button
              onClick={handleLogout}
              className="flex items-center w-full py-2 px-4 rounded-md hover:bg-red-600 transition-colors duration-150"
            >
              <MdExitToApp className="mr-2" /> Cerrar Sesión
            </button>
          </div>
        </div>
      </aside>
      <nav className="fixed inset-x-0 bottom-0 bg-gray-800 md:hidden flex justify-around items-center h-16 z-10">
        <NavLink
          to="/dashboard"
          className="flex flex-col items-center text-gray-200"
        >
          <FaHome className="text-lg" />
          <span className="text-sm">Inicio</span>
        </NavLink>
        <NavLink
          to="/dashboard/products"
          className="flex flex-col items-center text-gray-200"
        >
          <FaShoppingBasket className="text-lg" />
          <span className="text-sm">Productos</span>
        </NavLink>
        <NavLink
          to="/dashboard/new-sale"
          className="flex flex-col items-center text-gray-200"
        >
          <FaPlusCircle className="text-lg" />
          <span className="text-sm">Nueva Venta</span>
        </NavLink>
        <NavLink
          to="/dashboard/sales"
          className="flex flex-col items-center text-gray-200"
        >
          <FaTags className="text-lg" />
          <span className="text-sm">Ventas</span>
        </NavLink>
        <div className="flex flex-col items-center text-gray-200 relative">
          <FaBars className="text-lg cursor-pointer" onClick={toggleMoreMenu} />
          {showMoreMenu && (
            <div className="absolute right-0 bottom-0 mb-16 bg-gray-800 py-2 px-4 w-48 rounded-md shadow-lg">
              {plan !== "Free" && (
                <>
                  <NavLink
                    to="/dashboard/clients"
                    className="block py-2 rounded-md hover:bg-blue-600 transition-colors duration-150"
                  >
                    <FaUsers className="inline mr-2" /> Clientes
                  </NavLink>
                  {plan !== "Basic" && (
                    <>
                      <NavLink
                        to="/dashboard/calendar"
                        className="block py-2 rounded-md hover:bg-blue-600 transition-colors duration-150"
                      >
                        <FaCalendarAlt className="inline mr-2" /> Calendario
                      </NavLink>
                      <NavLink
                        to="/dashboard/cashbox"
                        className="block py-2 rounded-md hover:bg-blue-600 transition-colors duration-150"
                      >
                        <FaCashRegister className="inline mr-2" /> Corte de Caja
                      </NavLink>
                      <NavLink
                        to="/dashboard/suppliers"
                        className="block py-2 rounded-md hover:bg-blue-600 transition-colors duration-150"
                      >
                        <FaTruck className="inline mr-2" /> Proveedores
                      </NavLink>
                      {plan === "Premium" && (
                        <>
                          <NavLink
                            to="/dashboard/billing"
                            className="block py-2 rounded-md hover:bg-blue-600 transition-colors duration-150"
                          >
                            <FaRegClipboard className="inline mr-2" /> Facturación
                          </NavLink>
                          <NavLink
                            to="/dashboard/team"
                            className="block py-2 rounded-md hover:bg-blue-600 transition-colors duration-150"
                          >
                            <FaUserTie className="inline mr-2" /> Equipo
                          </NavLink>
                        </>
                      )}
                    </>
                  )}
                  <NavLink
                    to="/dashboard/license"
                    className="block py-2 rounded-md hover:bg-blue-600 transition-colors duration-150"
                  >
                    <FaKey className="inline mr-2" /> Licencia
                  </NavLink>
                </>
              )}
              <NavLink
                to="/dashboard/settings"
                className="block py-2 rounded-md hover:bg-blue-600 transition-colors duration-150"
              >
                <IoSettingsSharp className="inline mr-2" /> Configuración
              </NavLink>
              <NavLink
                to="/dashboard/support"
                className="block py-2 rounded-md hover:bg-blue-600 transition-colors duration-150"
              >
                <FaHeadset className="inline mr-2" /> Soporte
              </NavLink>
              <button
                onClick={handleLogout}
                className="block py-2 rounded-md hover:bg-red-600 transition-colors duration-150"
              >
                <MdExitToApp className="inline mr-2" /> Cerrar Sesión
              </button>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default SideBar;
