import React, { useEffect, useState } from 'react';
import useAxios from '../../hook/useAxios';
import useSocket from '../../hook/useSocket';
import { useTable, usePagination } from 'react-table';
import Skeleton from 'react-loading-skeleton';
import toast from 'react-hot-toast';
import ModalClient from '../../Components/Dashboard/ModalClient';
import { FaEdit, FaTrash } from 'react-icons/fa';

const Clients = () => {
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setModalOpen] = useState(false);
    const [currentClient, setCurrentClient] = useState(null);
    const token = localStorage.getItem('token');
    const socket = useSocket(token);

    const { execute: fetchClients } = useAxios(
        {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/api/clients`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        },
        (data) => {
            setClients(data);
            setLoading(false);
        },
        (error) => {
            console.error('Error fetching clients:', error.response?.data || error.message);
            toast.error('Error al obtener la lista de clientes');
            setLoading(false);
        }
    );

    const { execute: saveClient } = useAxios(
        {
            method: currentClient ? 'put' : 'post',
            url: currentClient ? `${process.env.REACT_APP_API_URL}/api/clients/${currentClient._id}` : `${process.env.REACT_APP_API_URL}/api/clients`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        },
        (data) => {
            fetchClients();
            setModalOpen(false);
            setCurrentClient(null);
            if (currentClient) {
                socket?.emit('client-updated', data);
                toast.success('Cliente actualizado exitosamente');
            } else {
                socket?.emit('client-added', data);
                toast.success('Cliente agregado exitosamente');
            }
        },
        (error) => {
            console.error(`Error ${currentClient ? 'actualizando' : 'agregando'} cliente:`, error.response?.data || error.message);
            toast.error(`Error ${currentClient ? 'actualizando' : 'agregando'} cliente`);
        }
    );

    const { execute: deleteClient } = useAxios(
        {
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}/api/clients/${currentClient?._id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        },
        (data) => {
            toast.success('Cliente eliminado exitosamente');
            fetchClients();
            socket?.emit('client-deleted', currentClient._id);
        },
        (error) => {
            console.error('Error eliminando cliente:', error.response?.data || error.message);
            toast.error('Error eliminando cliente');
        }
    );

    useEffect(() => {
        if (token) {
            fetchClients();
        }
    }, [token]);

    useEffect(() => {
        if (socket) {
            socket.on('client-added', (client) => {
                setClients((prevClients) => [...prevClients, client]);
            });

            socket.on('client-updated', (updatedClient) => {
                setClients((prevClients) =>
                    prevClients.map((client) =>
                        client._id === updatedClient._id ? updatedClient : client
                    )
                );
            });

            socket.on('client-deleted', (clientId) => {
                setClients((prevClients) =>
                    prevClients.filter((client) => client._id !== clientId)
                );
            });
        }
    }, [socket]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'name',
            },
            {
                Header: 'Correo Electrónico',
                accessor: 'email',
            },
            {
                Header: 'Teléfono',
                accessor: 'phone',
            },
            {
                Header: 'Dirección',
                accessor: 'address',
            },
            {
                Header: 'Acciones',
                accessor: 'actions',
                Cell: ({ row }) => (
                    <div className="flex space-x-2">
                        <button onClick={() => handleEdit(row.original)} className="text-blue-400 hover:text-blue-600"><FaEdit /></button>
                        <button onClick={() => handleDelete(row.original)} className="text-red-400 hover:text-red-600"><FaTrash /></button>
                    </div>
                ),
            },
        ],
        []
    );

    const handleEdit = (client) => {
        setCurrentClient(client);
        setModalOpen(true);
    };

    const handleDelete = (client) => {
        setCurrentClient(client);
        deleteClient({ url: `${process.env.REACT_APP_API_URL}/api/clients/${client._id}` });
    };

    const handleSave = (clientData) => {
        setCurrentClient(clientData);
        saveClient({ data: clientData });
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
    } = useTable(
        {
            columns,
            data: clients,
            initialState: { pageIndex: 0 },
        },
        usePagination
    );

    return (
        <div className="md:ml-[256px] p-8 min-h-screen flex flex-col overflow-hidden bg-gray-900 text-white">
            <h1 className="text-2xl font-semibold mb-6">Clientes</h1>
            <button onClick={() => {
                setCurrentClient(null); // Clear current client when adding a new one
                setModalOpen(true);
            }} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mb-4">Agregar Cliente</button>
            {loading ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                    {Array.from({ length: 8 }).map((_, index) => (
                        <div key={index} className="p-4 bg-gray-800 rounded shadow">
                            <Skeleton height={20} width="80%" />
                            <Skeleton height={20} width="60%" />
                            <Skeleton height={20} width="40%" />
                        </div>
                    ))}
                </div>
            ) : (
                <div className="overflow-x-auto">
                    <div className="hidden md:block">
                        <table {...getTableProps()} className="min-w-full bg-gray-800 border rounded-md shadow">
                            <thead className="bg-gray-700">
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps()} className="p-2 md:p-4 text-left text-gray-300">
                                                {column.render('Header')}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map(row => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()} className="border-t border-gray-700">
                                            {row.cells.map(cell => (
                                                <td {...cell.getCellProps()} className="p-2 md:p-4 text-gray-300">
                                                    {cell.render('Cell')}
                                                </td>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="md:hidden">
                        {page.map(row => {
                            prepareRow(row);
                            const rowData = row.original;
                            return (
                                <div key={row.id} className="border rounded-md p-4 mb-4 shadow bg-gray-800">
                                    {columns.map(column => (
                                        <div key={column.accessor} className="flex justify-between mb-2">
                                            <span className="font-semibold">{column.Header}</span>
                                            <span>{column.accessor === 'actions' ? null : rowData[column.accessor]}</span>
                                        </div>
                                    ))}
                                    <div className="flex space-x-2">
                                        <button onClick={() => handleEdit(row.original)} className="text-blue-400 hover:text-blue-600"><FaEdit /></button>
                                        <button onClick={() => handleDelete(row.original)} className="text-red-400 hover:text-red-600"><FaTrash /></button>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
            {isModalOpen && (
                <ModalClient
                    isOpen={isModalOpen}
                    onClose={() => setModalOpen(false)}
                    onSave={handleSave}
                    clientData={currentClient}
                />
            )}
        </div>
    );
};

export default Clients;
