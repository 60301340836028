import React, { useState, useEffect } from 'react';
import useAxios from '../../hook/useAxios';
import toast from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Modal from '../../Components/Dashboard/Modal';

const TeamPage = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [userFormData, setUserFormData] = useState({
        name: '',
        email: '',
        password: '',
        role: 'vendedor',
    });
    const [currentUser, setCurrentUser] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const token = localStorage.getItem('token');

    const { execute: fetchUsers } = useAxios(
        {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/api/team/users`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        },
        (data) => {
            setUsers(data);
            setLoading(false);
        },
        (error) => {
            console.error('Error fetching users:', error.response?.data || error.message);
            toast.error('Error al obtener la lista de usuarios');
            setLoading(false);
        }
    );

    const { execute: createUser } = useAxios(
        {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/api/registerDash`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            data: userFormData,
        },
        (data) => {
            toast.success('Usuario creado exitosamente.');
            setUserFormData({
                name: '',
                email: '',
                password: '',
                role: 'vendedor',
            });
            fetchUsers();
            setModalOpen(false);
        },
        (error) => {
            console.error('Error creando usuario:', error.response?.data || error.message);
            const errorMessage = error.response?.data?.message || 'Error al crear el usuario';
            toast.error(errorMessage);
        }
    );

    const { execute: updateUser } = useAxios(
        {
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}/api/team/users/${currentUser?._id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            data: userFormData,
        },
        (data) => {
            toast.success('Usuario actualizado exitosamente.');
            fetchUsers();
            setModalOpen(false);
            setCurrentUser(null);
        },
        (error) => {
            console.error('Error actualizando usuario:', error.response?.data || error.message);
            toast.error('Error al actualizar el usuario');
        }
    );

    const { execute: deleteUser } = useAxios(
        {
            method: 'delete',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        },
        (data) => {
            toast.success('Usuario eliminado exitosamente.');
            fetchUsers();
            setDeleteModalOpen(false);
        },
        (error) => {
            console.error('Error eliminando usuario:', error.response?.data || error.message);
            toast.error('Error al eliminar el usuario');
        }
    );

    useEffect(() => {
        if (token) {
            fetchUsers();
        }
    }, [token]);

    const handleCreateUser = () => {
        setCurrentUser(null);
        setUserFormData({
            name: '',
            email: '',
            password: '',
            role: 'vendedor',
        });
        setModalOpen(true);
    };

    const handleEditUser = (user) => {
        setCurrentUser(user);
        setUserFormData({
            name: user.name,
            email: user.email,
            password: '',
            role: user.role,
        });
        setModalOpen(true);
    };

    const handleSaveUser = () => {
        const { name, email, password, role } = userFormData;
        const updateData = { name, email, role };
        if (password) updateData.password = password;

        if (currentUser) {
            updateUser({ data: updateData });
        } else {
            createUser();
        }
    };

    const handleDeleteUser = (user) => {
        setCurrentUser(user);
        setDeleteModalOpen(true);
    };

    const confirmDeleteUser = () => {
        deleteUser({ url: `${process.env.REACT_APP_API_URL}/api/team/users/${currentUser._id}` });
    };

    const filteredUsers = users.filter(user =>
        user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="md:ml-[256px] p-8 min-h-screen flex flex-col overflow-hidden dark:bg-gray-900">
            <h1 className="text-2xl font-semibold mb-6 text-gray-800 dark:text-gray-100">Equipo</h1>
            <div className="mb-4 flex justify-between items-center">
                <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300"
                    placeholder="Buscar por nombre o correo electrónico"
                />
                <button onClick={handleCreateUser} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded ml-4">Agregar Usuario al Equipo</button>
            </div>
            {loading ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                    {Array.from({ length: 8 }).map((_, index) => (
                        <div key={index} className="p-4 bg-white dark:bg-gray-800 rounded shadow">
                            <Skeleton height={20} width="80%" />
                            <Skeleton height={20} width="60%" />
                            <Skeleton height={20} width="40%" />
                        </div>
                    ))}
                </div>
            ) : (
                <div className="overflow-x-auto">
                    <div className="hidden md:block">
                        <div className="max-h-96 overflow-y-auto">
                            <table className="min-w-full bg-white dark:bg-gray-800 border rounded-md shadow">
                                <thead className="bg-gray-100 dark:bg-gray-700">
                                    <tr>
                                        <th className="p-2 md:p-4 text-left text-gray-800 dark:text-gray-300">Nombre</th>
                                        <th className="p-2 md:p-4 text-left text-gray-800 dark:text-gray-300">Correo Electrónico</th>
                                        <th className="p-2 md:p-4 text-left text-gray-800 dark:text-gray-300">Rol</th>
                                        <th className="p-2 md:p-4 text-left text-gray-800 dark:text-gray-300">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredUsers.length > 0 ? (
                                        filteredUsers.map(user => (
                                            <tr key={user._id} className="border-t dark:border-gray-700">
                                                <td className="p-2 md:p-4 text-gray-800 dark:text-gray-300">{user.name}</td>
                                                <td className="p-2 md:p-4 text-gray-800 dark:text-gray-300">{user.email}</td>
                                                <td className="p-2 md:p-4 text-gray-800 dark:text-gray-300">{user.role}</td>
                                                <td className="p-2 md:p-4">
                                                    <button onClick={() => handleEditUser(user)} className="text-blue-500 hover:text-blue-700 dark:hover:text-blue-400 mr-2"><FaEdit /></button>
                                                    <button onClick={() => handleDeleteUser(user)} className="text-red-500 hover:text-red-700 dark:hover:text-red-400"><FaTrash /></button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="4" className="p-4 text-center text-gray-500 dark:text-gray-300">No se encontraron usuarios</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="md:hidden">
                        {filteredUsers.length > 0 ? (
                            filteredUsers.map(user => (
                                <div key={user._id} className="border rounded-md p-4 mb-4 shadow bg-white dark:bg-gray-800">
                                    <div className="flex justify-between mb-2">
                                        <span className="font-semibold text-gray-800 dark:text-gray-300">Nombre</span>
                                        <span className="text-gray-800 dark:text-gray-300">{user.name}</span>
                                    </div>
                                    <div className="flex justify-between mb-2">
                                        <span className="font-semibold text-gray-800 dark:text-gray-300">Correo Electrónico</span>
                                        <span className="text-gray-800 dark:text-gray-300">{user.email}</span>
                                    </div>
                                    <div className="flex justify-between mb-2">
                                        <span className="font-semibold text-gray-800 dark:text-gray-300">Rol</span>
                                        <span className="text-gray-800 dark:text-gray-300">{user.role}</span>
                                    </div>
                                    <div className="flex space-x-2">
                                        <button onClick={() => handleEditUser(user)} className="text-blue-500 hover:text-blue-700 dark:hover:text-blue-400"><FaEdit /></button>
                                        <button onClick={() => handleDeleteUser(user)} className="text-red-500 hover:text-red-700 dark:hover:text-red-400"><FaTrash /></button>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="p-4 text-center text-gray-500 dark:text-gray-300">No se encontraron usuarios</div>
                        )}
                    </div>
                </div>
            )}
            {isModalOpen && (
                <Modal onClose={() => setModalOpen(false)}>
                    <h2 className="text-lg font-semibold mb-4 text-gray-800 dark:text-gray-100">{currentUser ? 'Editar Usuario' : 'Agregar Usuario'}</h2>
                    <div className="space-y-4">
                        <input
                            id="userName"
                            type="text"
                            value={userFormData.name}
                            onChange={(e) => setUserFormData({ ...userFormData, name: e.target.value })}
                            className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300"
                            placeholder="Nombre del usuario"
                        />
                        <input
                            id="userEmail"
                            type="email"
                            value={userFormData.email}
                            onChange={(e) => setUserFormData({ ...userFormData, email: e.target.value })}
                            className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300"
                            placeholder="Correo electrónico del usuario"
                        />
                        <input
                            id="userPassword"
                            type="password"
                            value={userFormData.password}
                            onChange={(e) => setUserFormData({ ...userFormData, password: e.target.value })}
                            className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300"
                            placeholder="Contraseña del usuario"
                        />
                        <select
                            id="userRole"
                            value={userFormData.role}
                            onChange={(e) => setUserFormData({ ...userFormData, role: e.target.value })}
                            className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300"
                        >
                            <option value="admin">Admin</option>
                            <option value="vendedor">Vendedor</option>
                        </select>
                    </div>
                    <div className="mt-4 flex justify-end space-x-4">
                        <button onClick={() => setModalOpen(false)} className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-2 px-4 rounded dark:bg-gray-600 dark:hover:bg-gray-700 dark:text-gray-300">Cancelar</button>
                        <button onClick={handleSaveUser} className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded">Guardar</button>
                    </div>
                </Modal>
            )}
            {isDeleteModalOpen && (
                <Modal onClose={() => setDeleteModalOpen(false)}>
                    <h2 className="text-lg font-semibold mb-4 text-gray-800 dark:text-gray-100">Confirmar Eliminación</h2>
                    <p className="text-gray-800 dark:text-gray-300">¿Estás seguro de que deseas eliminar al usuario <strong>{currentUser?.name}</strong>? Esta acción no se puede deshacer.</p>
                    <div className="mt-4 flex justify-end space-x-4">
                        <button onClick={() => setDeleteModalOpen(false)} className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-2 px-4 rounded dark:bg-gray-600 dark:hover:bg-gray-700 dark:text-gray-300">Cancelar</button>
                        <button onClick={confirmDeleteUser} className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded">Eliminar</button>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default TeamPage;
