import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  FaDollarSign,
  FaChartBar,
  FaShoppingCart,
  FaChartLine,
  FaUsers,
  FaCalendarAlt,
  FaMoneyBill,
  FaEdit,
  FaTimes,
} from "react-icons/fa";
import KpiCard from "../../Components/KpiCard";
import Modal from "../../Components/Dashboard/Modal";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../../assets/Main.css"; // Asegúrate de importar el archivo CSS

const Main = () => {
  const [userName, setUserName] = useState("");
  const [plan, setPlan] = useState("");
  const [kpiData, setKpiData] = useState({});
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [selectedKPIs, setSelectedKPIs] = useState([]);
  const [availableKPIs, setAvailableKPIs] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const kpiItems = [
    {
      id: "totalRevenue",
      title: "Ingresos Totales",
      icon: <FaMoneyBill />,
      color: "bg-purple-200 text-purple-800",
    },
    {
      id: "totalSales",
      title: "Ventas Totales",
      icon: <FaChartBar />,
      color: "bg-gray-200 text-gray-800",
    },
    {
      id: "highestSale",
      title: "Venta Mayor",
      icon: <FaShoppingCart />,
      color: "bg-yellow-200 text-yellow-800",
    },
    {
      id: "lowestSale",
      title: "Venta Menor",
      icon: <FaShoppingCart />,
      color: "bg-red-200 text-red-800",
    },
    {
      id: "totalProducts",
      title: "Total Productos",
      icon: <FaChartLine />,
      color: "bg-purple-200 text-purple-800",
    },
    {
      id: "topSellingProducts",
      title: "Productos Más Vendidos",
      icon: <FaChartBar />,
      color: "bg-blue-200 text-blue-800",
    },
    {
      id: "nonSellingProducts",
      title: "Productos No Vendidos",
      icon: <FaChartBar />,
      color: "bg-blue-200 text-blue-800",
    },
    {
      id: "totalClients",
      title: "Total Clientes",
      icon: <FaUsers />,
      color: "bg-indigo-200 text-indigo-800",
    },
    {
      id: "salesPerClient",
      title: "Ventas por Cliente",
      icon: <FaChartLine />,
      color: "bg-gray-200 text-gray-800",
    },
    {
      id: "avgRevenuePerSale",
      title: "Promedio de Ingresos por Venta",
      icon: <FaDollarSign />,
      color: "bg-green-200 text-green-800",
    },
    {
      id: "avgProductsPerSale",
      title: "Promedio de Productos por Venta",
      icon: <FaShoppingCart />,
      color: "bg-yellow-200 text-yellow-800",
    },
    {
      id: "totalEvents",
      title: "Cantidad de Eventos",
      icon: <FaCalendarAlt />,
      color: "bg-teal-200 text-teal-800",
    },
    {
      id: "monthlyGrowth",
      title: "Crecimiento Mensual",
      icon: <FaChartLine />,
      color: "bg-green-200 text-green-800",
    },
    {
      id: "monthlyNewClients",
      title: "Nuevos Clientes Mensuales",
      icon: <FaUsers />,
      color: "bg-blue-200 text-blue-800",
    },
    {
      id: "productsInStock",
      title: "Productos en Stock",
      icon: <FaShoppingCart />,
      color: "bg-gray-200 text-gray-800",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const userPlan = localStorage.getItem("plan");
        if (!token) {
          console.error("No token found");
          return;
        }
        setPlan(userPlan);

        const userResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/users/me`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUserName(userResponse.data.name);

        const kpiResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/kpis`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setKpiData(kpiResponse.data);

        const settingsResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/settings`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const savedKPIs = settingsResponse.data.selectedKPIs;
        const initialSelectedKPIs = kpiItems.filter((kpi) =>
          savedKPIs.includes(kpi.id)
        );
        const initialAvailableKPIs = kpiItems.filter(
          (kpi) => !savedKPIs.includes(kpi.id)
        );

        setSelectedKPIs(initialSelectedKPIs);
        setAvailableKPIs(initialAvailableKPIs);

        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setErrorMessage("Sesion expirada, loguea de nuevo.");
        } else {
          console.error("Error fetching data:", error);
        }
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleEditClick = async () => {
    if (editMode) {
      await saveSettings();
    }
    setEditMode(!editMode);
    setModalOpen(!editMode);
  };

  const handleRemoveKpi = (id) => {
    setAvailableKPIs([
      ...availableKPIs,
      ...selectedKPIs.filter((kpi) => kpi.id === id),
    ]);
    setSelectedKPIs(selectedKPIs.filter((kpi) => kpi.id !== id));
  };

  const handleAddKpi = (id) => {
    setSelectedKPIs([
      ...selectedKPIs,
      ...availableKPIs.filter((kpi) => kpi.id === id),
    ]);
    setAvailableKPIs(availableKPIs.filter((kpi) => kpi.id !== id));
  };

  const saveSettings = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/settings`,
        {
          selectedKPIs: selectedKPIs.map((kpi) => kpi.id),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("KPIs guardados exitosamente.");
    } catch (error) {
      console.error("Error saving settings:", error);
      toast.error("Error al guardar los KPIs.");
    }
  };

  const getKpiValue = (kpiId) => {
    const kpiValue = kpiData[kpiId];
    if (kpiId === "topSellingProducts" || kpiId === "nonSellingProducts") {
      if (Array.isArray(kpiValue) && kpiValue.length > 0) {
        return kpiValue.map((product) => product.name).join(", ");
      } else {
        return "Información insuficiente";
      }
    }
    if (kpiValue == null || kpiValue === "") {
      return "Información insuficiente";
    }
    switch (kpiId) {
      case "totalRevenue":
      case "highestSale":
      case "lowestSale":
      case "avgRevenuePerSale":
        return parseFloat(kpiValue).toFixed(2);
      case "totalSales":
      case "totalProducts":
      case "totalClients":
      case "totalEvents":
      case "monthlyNewClients":
      case "productsInStock":
        return parseInt(kpiValue, 10);
      case "avgProductsPerSale":
      case "monthlyGrowth":
      case "salesPerClient":
        return parseFloat(kpiValue).toFixed(2);
      default:
        return kpiValue;
    }
  };

  return (
    <div className="md:ml-[256px] min-h-screen p-8 relative bg-gray-900 text-white">
      {errorMessage && (
        <div className="bg-red-500 text-white p-4 rounded mb-4">
          {errorMessage}
        </div>
      )}
      <div className="mb-4 flex justify-between items-center">
        <h1 className="text-xl md:text-2xl font-semibold">Tablero</h1>
        {(plan === "Standard" || plan === "Premium") && (
          <button
            onClick={handleEditClick}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            {editMode ? "Terminar" : "Editar"} <FaEdit className="inline ml-2" />
          </button>
        )}
      </div>
      <h2 className="text-lg md:text-xl font-semibold">
        Bienvenido {loading ? <Skeleton width={100} /> : userName}
      </h2>
      <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4`}>
        {loading
          ? Array.from({ length: 5 }).map((_, index) => (
              <div key={index} className="bg-gray-800 shadow rounded p-4">
                <Skeleton height={64} width={64} />
                <div className="mt-4">
                  <Skeleton height={20} width="80%" />
                  <Skeleton height={20} width="60%" />
                  <Skeleton height={20} width="40%" />
                </div>
              </div>
            ))
          : selectedKPIs.map((kpi) => (
              <div key={kpi.id} className="relative">
                {editMode && (
                  <button
                    onClick={() => handleRemoveKpi(kpi.id)}
                    className="absolute top-0 right-0 mt-2 mr-2 bg-red-500 text-white rounded-full p-1"
                  >
                    <FaTimes />
                  </button>
                )}
                <KpiCard
                  icon={kpi.icon}
                  title={kpi.title}
                  value={getKpiValue(kpi.id)}
                  color={kpi.color}
                />
              </div>
            ))}
      </div>
      
      {editMode && modalOpen && (
        <Modal onClose={() => setModalOpen(false)}>
          <h3 className="text-lg font-semibold mb-4">Agregar KPIs</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {availableKPIs.map((kpi) => (
              <div
                key={kpi.id}
                className="m-2 flex justify-between items-center border p-2 rounded-lg"
              >
                <span>{kpi.title}</span>
                <button
                  onClick={() => handleAddKpi(kpi.id)}
                  className="bg-green-500 hover:bg-green-700 text-white py-1 px-2 rounded"
                >
                  Agregar
                </button>
              </div>
            ))}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Main;
