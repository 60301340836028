// src/hook/useSocket.js
import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';

const useSocket = (token) => {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        if (token) {
            const newSocket = io(process.env.REACT_APP_API_URL, {
                auth: { token }
            });
            setSocket(newSocket);

            return () => {
                newSocket.close();
            };
        }
    }, [token]);

    return socket;
};

export default useSocket;
