import React, { useState, useEffect } from "react";
import { pdf } from "@react-pdf/renderer";
import Autosuggest from "react-autosuggest";
import {
  FaTrash,
  FaPlus,
  FaWindowClose,
  FaEllipsisV,
  FaChevronDown,
  FaChevronRight,
} from "react-icons/fa";
import useSocket from "../../hook/useSocket";
import axios from "axios";
import toast from "react-hot-toast";
import useAxios from "../../hook/useAxios";
import {
  InvoiceTemplate1,
  InvoiceTemplate2,
  InvoiceTemplate3,
} from "../../Components/Billing/InvoiceTemplates";

const SaleTab = ({ id, isActive, onSelect, onClose }) => (
  <div
    className={`px-3 py-2 cursor-pointer flex items-center justify-between ${
      isActive ? "bg-blue-600 text-white" : "bg-gray-700 text-gray-200"
    } rounded-t`}
    onClick={() => onSelect(id)}
    style={{ flex: "0 0 auto", maxWidth: "150px" }}
  >
    <span className="mr-2 text-sm">Venta</span>
    <FaWindowClose
      className="text-red-500 hover:text-red-700"
      onClick={(e) => {
        e.stopPropagation();
        onClose(id);
      }}
    />
  </div>
);

const NewSale = () => {
  const [expandedItemIndex, setExpandedItemIndex] = useState(-1);
  const [sales, setSales] = useState([]);
  const [activeSaleId, setActiveSaleId] = useState(null);
  const [products, setProducts] = useState([]);
  const [clients, setClients] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [newClient, setNewClient] = useState({
    name: "",
    email: "",
    address: "",
    phone: "",
  });
  const [searchClient, setSearchClient] = useState("");
  const [companyId, setCompanyId] = useState(null);
  const [taxRate, setTaxRate] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [sectionVisible, setSectionVisible] = useState({
    client: true,
    products: true,
    cart: true,
  });
  const [settings, setSettings] = useState({
    selectedTemplate: "template1",
    logoUrl: '', // Añadido para el logo
  });
  const [paymentMethod, setPaymentMethod] = useState('Tarjeta'); // Nuevo estado para método de pago
  const token = localStorage.getItem("token");
  const socket = useSocket(token);
  const [loading, setLoading] = useState(false); // Estado de carga añadido

  useEffect(() => {
    const handleResize = () => {
      setMobileView(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const user = response.data;
        if (user && user.company) {
          setCompanyId(user.company._id);
        } else {
          console.error("No company associated with user");
        }
      } catch (error) {
        console.error("Error al obtener detalles del usuario:", error);
      }
    };
    
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/products`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProducts(response.data);
      } catch (error) {
        console.error("Error al obtener productos:", error);
      }
    };
    
    const fetchClients = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/clients`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setClients(response.data);
      } catch (error) {
        console.error("Error al obtener clientes:", error);
      }
    };
    
    const fetchCompanySettings = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/settings`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setTaxRate(response.data.taxRate || 0);
        setSettings({
          selectedTemplate: response.data.selectedTemplate,
          logoUrl: response.data.logoUrl || '',
        });
      } catch (error) {
        console.error(
          "Error al obtener configuraciones de la compañía:",
          error
        );
      }
    };
    

    fetchUserDetails();
    fetchProducts();
    fetchClients();
    fetchCompanySettings();
  }, [token]);

  const toggleItemExpansion = (index) => {
    if (expandedItemIndex === index) {
      setExpandedItemIndex(-1);
    } else {
      setExpandedItemIndex(index);
    }
  };

  const addNewSale = () => {
    const newSaleId = sales.length + 1;
    const newSale = {
      id: newSaleId,
      cartItems: [],
      total: 0,
      searchTerm: "",
      client: null,
    };
    setSales([...sales, newSale]);
    setActiveSaleId(newSaleId);
  };

  const generatePDFBlob = async (invoiceData, template, logoUrl) => {
    let document;
    switch (template) {
      case "template2":
        document = <InvoiceTemplate2 invoice={invoiceData} logoUrl={logoUrl} />;
        break;
      case "template3":
        document = <InvoiceTemplate3 invoice={invoiceData} logoUrl={logoUrl} />;
        break;
      case "template1":
      default:
        document = <InvoiceTemplate1 invoice={invoiceData} logoUrl={logoUrl} />;
        break;
    }
    const blob = await pdf(document).toBlob();
    return blob;
  };

  const closeSale = (id) => {
    const filteredSales = sales.filter((sale) => sale.id !== id);
    setSales(filteredSales);
    if (filteredSales.length > 0) {
      setActiveSaleId(filteredSales[0].id);
    } else {
      setActiveSaleId(null);
    }
  };

  const changeActiveSale = (id) => {
    setActiveSaleId(id);
  };

  const currentSale = sales.find((sale) => sale.id === activeSaleId);

  const updateSearchTerm = (term) => {
    setSales(
      sales.map((sale) =>
        sale.id === activeSaleId ? { ...sale, searchTerm: term } : sale
      )
    );
  };

  const updateClient = (client) => {
    setSales(
      sales.map((sale) =>
        sale.id === activeSaleId ? { ...sale, client } : sale
      )
    );
  };

  const addToCart = (product) => {
    const productInCart = currentSale.cartItems.find(
      (item) => item.productId === product._id
    );
    const cartQuantity = productInCart ? productInCart.quantity : 0;

    if (cartQuantity + 1 > product.quantity) {
      toast.error("No hay suficiente stock del producto seleccionado");
      return;
    }

    setSales(
      sales.map((sale) => {
        if (sale.id === activeSaleId) {
          const existingItemIndex = sale.cartItems.findIndex(
            (item) => item.productId === product._id
          );
          const updatedCartItems =
            existingItemIndex !== -1
              ? sale.cartItems.map((item, index) =>
                  index === existingItemIndex
                    ? { ...item, quantity: item.quantity + 1 }
                    : item
                )
              : [
                  ...sale.cartItems,
                  {
                    productId: product._id,
                    name: product.name,
                    price: product.price,
                    quantity: 1,
                    discount: product.useDiscount ? product.discount : 0,
                    applyTax: product.applyTax,
                    tax: product.tax,
                  },
                ];

          const updatedTotal = calculateTotal(updatedCartItems);

          return { ...sale, cartItems: updatedCartItems, total: updatedTotal };
        }
        return sale;
      })
    );
  };

  const { execute: saveClient } = useAxios(
    {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/clients`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
    (data) => {
      setClients([...clients, data]);
      toast.success("Cliente agregado exitosamente");
    },
    (error) => {
      console.error(
        "Error agregando cliente:",
        error.response?.data || error.message
      );
      toast.error("Error agregando cliente");
    }
  );

  const handleAddClient = () => {
    if (
      newClient.name &&
      newClient.email &&
      newClient.address &&
      newClient.phone
    ) {
      saveClient({ data: newClient });
    } else {
      toast.error("Por favor, complete todos los campos del cliente");
    }
  };

  const removeFromCart = (index) => {
    setSales(
      sales.map((sale) => {
        if (sale.id === activeSaleId) {
          const item = sale.cartItems[index];
          const updatedProducts = products.map((p) =>
            p._id === item.productId
              ? { ...p, quantity: p.quantity + item.quantity }
              : p
          );
          setProducts(updatedProducts);
          let newCartItems = sale.cartItems.filter((_, i) => i !== index);
          let newTotal = calculateTotal(newCartItems);
          return { ...sale, cartItems: newCartItems, total: newTotal };
        }
        return sale;
      })
    );
  };

  const saveSale = async () => {
    if (!currentSale || !companyId) return;

    setLoading(true); // Iniciar carga

    const tax = parseFloat(calculateTax(currentSale.cartItems));
    const total = parseFloat(calculateTotal(currentSale.cartItems));

    const pdfBlob = await generatePDFBlob(
      {
        ...currentSale,
        invoiceNumber: currentSale.id, // Asignando número de factura
        date: new Date().toLocaleDateString(), // Asignando fecha actual
        items: currentSale.cartItems.map((item) => ({
          description: item.name,
          quantity: item.quantity,
          unitPrice: item.price,
          total: item.price * item.quantity,
        })),
        tax: tax,
        total: total // Calcula y asigna el total
      },
      settings.selectedTemplate,
      settings.logoUrl // Pasando logoUrl
    );

    const reader = new FileReader();
    reader.readAsDataURL(pdfBlob);
    reader.onloadend = async () => {
      const base64data = reader.result;

      try {
        const saleData = {
          companyId: companyId,
          cartItems: currentSale.cartItems,
          tax: tax,
          total: total, // Calcula y asigna el total
          clientId: currentSale.client?._id,
          clientDetails: currentSale.client,
          pdfBase64: base64data,
          selectedTemplate: settings.selectedTemplate,
          paymentMethod // Añadir método de pago
        };

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/sales/add`,
          saleData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        for (const item of currentSale.cartItems) {
          await axios.put(
            `${process.env.REACT_APP_API_URL}/api/products/${item.productId}/decreaseStock`,
            {
              quantity: item.quantity,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
        }

        socket.emit("sale-added", response.data);
        toast.success("Venta realizada con éxito");
        closeSale(activeSaleId);
      } catch (error) {
        console.error("Error al guardar la venta:", error);
      } finally {
        setLoading(false); // Finalizar carga
      }
    };
  };

  useEffect(() => {
    if (socket) {
      socket.on("sale-added", (sale) => {});
    }
  }, [socket]);

  const calculateSubtotal = (items) => {
    return items
      .reduce(
        (acc, item) =>
          acc + item.price * item.quantity * (1 - (item.discount || 0) / 100),
        0
      )
      .toFixed(2);
  };

  const calculateTax = (items) => {
    const subtotal = items.reduce(
      (acc, item) =>
        acc + item.price * item.quantity * (1 - (item.discount || 0) / 100),
      0
    );
    return (subtotal * (taxRate / 100)).toFixed(2);
  };

  const calculateTotal = (items) => {
    const subtotal = items.reduce(
      (acc, item) =>
        acc + item.price * item.quantity * (1 - (item.discount || 0) / 100),
      0
    );
    const tax = subtotal * (taxRate / 100);
    return (subtotal + tax).toFixed(2);
  };

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
      ? []
      : clients
          .filter((client) => client.name.toLowerCase().includes(inputValue))
          .slice(0, 5); // Limitar el número de sugerencias a 5
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    updateClient(suggestion);
    setSearchClient(suggestion.name);
  };

  const renderSuggestion = (suggestion) => (
    <div className="suggestion-item p-2 hover:bg-gray-700 cursor-pointer">
      {suggestion.name}
    </div>
  );

  const onSearchClientChange = (event, { newValue }) => {
    setSearchClient(newValue);
  };

  const inputProps = {
    placeholder: "Buscar Cliente...",
    value: searchClient,
    onChange: onSearchClientChange,
  };

  const updateCartQuantity = (index, newQuantity) => {
    setSales(
      sales.map((sale) => {
        if (sale.id === activeSaleId) {
          const item = sale.cartItems[index];
          const product = products.find((p) => p._id === item.productId);

          if (newQuantity > product.quantity) {
            toast.error("No hay suficiente stock del producto seleccionado");
            return sale;
          }

          const updatedCartItems = sale.cartItems
            .map((item, i) =>
              i === index ? { ...item, quantity: newQuantity } : item
            )
            .filter((item) => item.quantity > 0);

          const updatedTotal = calculateTotal(updatedCartItems);

          return { ...sale, cartItems: updatedCartItems, total: updatedTotal };
        }
        return sale;
      })
    );
  };

  const subtotal = currentSale ? parseFloat(calculateSubtotal(currentSale.cartItems)) : 0;
  const tax = currentSale ? parseFloat(calculateTax(currentSale.cartItems)) : 0;
  const total = currentSale ? parseFloat(calculateTotal(currentSale.cartItems)) : 0;

  return (
    <div className="md:ml-[256px] min-h-screen max-w-screen p-8 bg-gray-900 text-white">
      <div className="bg-gray-800 p-4 rounded-lg">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Ventas</h2>
          <button
            onClick={addNewSale}
            className="bg-blue-600 hover:bg-blue-700 text-white font-semibold px-6 py-3 rounded-lg transition-colors duration-200 ease-in-out flex items-center space-x-2 shadow-lg"
          >
            <FaPlus className="text-white" />
            <span>Añadir Venta</span>
          </button>
        </div>
        <div className="flex flex-row overflow-x-auto max-w-72 sm:max-w-md lg:max-w-lg xl:max-w-full">
          {sales.slice(0, 10).map((sale) => (
            <SaleTab
              key={sale.id}
              id={sale.id}
              isActive={sale.id === activeSaleId}
              onSelect={changeActiveSale}
              onClose={closeSale}
            />
          ))}
        </div>
      </div>
      {currentSale && (
        <div
          className={`bg-gray-800 p-4 border-t mt-4 rounded-lg shadow-sm ${
            mobileView ? "flex flex-col" : "flex flex-col lg:flex-row"
          }`}
        >
          <div className={`order-1 lg:order-none space-y-6 p-4 flex-grow`}>
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-semibold">Cliente</h3>
              {mobileView && (
                <div
                  onClick={() =>
                    setSectionVisible({
                      ...sectionVisible,
                      client: !sectionVisible.client,
                    })
                  }
                  className="cursor-pointer"
                >
                  {sectionVisible.client ? (
                    <FaChevronDown />
                  ) : (
                    <FaChevronRight />
                  )}
                </div>
              )}
            </div>
            {(sectionVisible.client || !mobileView) && (
              <>
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  onSuggestionSelected={onSuggestionSelected}
                  getSuggestionValue={(suggestion) => suggestion.name}
                  renderSuggestion={renderSuggestion}
                  inputProps={inputProps}
                  theme={{
                    container: "relative",
                    suggestionsContainer:
                      "absolute z-10 w-full bg-gray-800 border border-gray-700 rounded-md shadow-lg mt-1 max-h-48 overflow-y-auto",
                    suggestion: "p-2 cursor-pointer",
                    suggestionHighlighted: "bg-gray-700",
                  }}
                />
                <div className="mt-4 p-4 border rounded-md shadow-sm bg-gray-700">
                  <label className="block text-sm font-medium text-gray-300 mb-2">
                    Agregar nuevo cliente:
                  </label>
                  <div className="space-y-4">
                    <input
                      type="text"
                      placeholder="Nombre del cliente"
                      className="block w-full border border-gray-600 rounded-md shadow-sm p-2 bg-gray-800 text-gray-300"
                      value={newClient.name}
                      onChange={(e) =>
                        setNewClient({ ...newClient, name: e.target.value })
                      }
                    />
                    <input
                      type="email"
                      placeholder="Email del cliente"
                      className="block w-full border border-gray-600 rounded-md shadow-sm p-2 bg-gray-800 text-gray-300"
                      value={newClient.email}
                      onChange={(e) =>
                        setNewClient({ ...newClient, email: e.target.value })
                      }
                    />
                    <input
                      type="text"
                      placeholder="Dirección del cliente"
                      className="block w-full border border-gray-600 rounded-md shadow-sm p-2 bg-gray-800 text-gray-300"
                      value={newClient.address}
                      onChange={(e) =>
                        setNewClient({ ...newClient, address: e.target.value })
                      }
                    />
                    <input
                      type="text"
                      placeholder="Teléfono del cliente"
                      className="block w-full border border-gray-600 rounded-md shadow-sm p-2 bg-gray-800 text-gray-300"
                      value={newClient.phone}
                      onChange={(e) =>
                        setNewClient({ ...newClient, phone: e.target.value })
                      }
                    />
                    <button
                      className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                      onClick={() => {
                        if (
                          newClient.name &&
                          newClient.email &&
                          newClient.address &&
                          newClient.phone
                        ) {
                          handleAddClient();
                          setNewClient({
                            name: "",
                            email: "",
                            address: "",
                            phone: "",
                          });
                        } else {
                          toast.error(
                            "Por favor, complete todos los campos del cliente"
                          );
                        }
                      }}
                    >
                      Agregar Cliente
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={`order-2 lg:order-none space-y-6 p-4 flex-grow`}>
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-semibold">Productos</h3>
              {mobileView && (
                <div
                  onClick={() =>
                    setSectionVisible({
                      ...sectionVisible,
                      products: !sectionVisible.products,
                    })
                  }
                  className="cursor-pointer"
                >
                  {sectionVisible.products ? (
                    <FaChevronDown />
                  ) : (
                    <FaChevronRight />
                  )}
                </div>
              )}
            </div>
            {(sectionVisible.products || !mobileView) && (
              <>
                <input
                  type="text"
                  placeholder="Buscar productos..."
                  className="p-2 border-2 border-gray-600 rounded w-full bg-gray-700 text-gray-300"
                  value={currentSale.searchTerm || ""}
                  onChange={(e) => updateSearchTerm(e.target.value)}
                />
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-8">
                  {products
                    .filter((product) =>
                      product.name
                        .toLowerCase()
                        .includes((currentSale.searchTerm || "").toLowerCase())
                    )
                    .map((product) => (
                      <div
                        key={product._id}
                        className={`p-2 border rounded-lg shadow hover:shadow-md transition bg-gray-800 cursor-pointer ${
                          product.quantity === 0
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }`}
                        onClick={() =>
                          product.quantity > 0 && addToCart(product)
                        }
                      >
                        {product.includeImage && (
                          <img
                            src={product.imageUrl}
                            alt={product.name}
                            className="h-24 w-full object-cover rounded-lg mb-2"
                          />
                        )}
                        <h3 className="font-bold text-gray-300">
                          {product.name} - ¢{product.price}
                        </h3>
                        <p className="text-sm text-gray-400">
                          Cantidad:{" "}
                          {product.quantity === 0
                            ? "Out of stock"
                            : product.quantity}
                        </p>
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>
          <div className={`order-3 lg:order-none space-y-6 p-4 flex-grow`}>
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-semibold">Carrito</h3>
              {mobileView && (
                <div
                  onClick={() =>
                    setSectionVisible({
                      ...sectionVisible,
                      cart: !sectionVisible.cart,
                    })
                  }
                  className="cursor-pointer"
                >
                  {sectionVisible.cart ? <FaChevronDown /> : <FaChevronRight />}
                </div>
              )}
            </div>
            {(sectionVisible.cart || !mobileView) && (
              <>
                <h2 className="text-xl font-semibold mb-4 px-4 py-2 border-b border-gray-600">
                  Carrito (
                  {currentSale.cartItems.reduce(
                    (acc, item) => acc + item.quantity,
                    0
                  )}{" "}
                  artículos)
                </h2>
                <div className="px-4 py-2 overflow-y-auto max-h-96">
                  {currentSale.cartItems.length === 0 && (
                    <div className="text-gray-400 py-2">
                      No hay productos en el carrito.
                    </div>
                  )}
                  {currentSale.cartItems.map((item, index) => (
                    <div
                      key={index}
                      className={`border rounded-md shadow mb-4 overflow-hidden ${
                        expandedItemIndex === index ? "bg-gray-700" : "bg-gray-800"
                      }`}
                    >
                      <div
                        className="flex justify-between items-center p-4 cursor-pointer"
                        onClick={() => toggleItemExpansion(index)}
                      >
                        <span className="text-lg font-medium text-gray-300">
                          {item.name} - ¢{item.price.toFixed(2)} (x
                          {item.quantity})
                        </span>
                        <div className="flex items-center">
                          <FaEllipsisV className="text-gray-500 hover:text-gray-700 mr-2" />
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              removeFromCart(index);
                            }}
                            className="text-red-500 hover:text-red-700"
                          >
                            <FaTrash />
                          </button>
                        </div>
                      </div>
                      {expandedItemIndex === index && (
                        <div className="text-sm p-4 overflow-auto max-h-44 sm:max-h-32 md:max-h-40 lg:max-h-48 bg-gray-700">
                          <div className="flex flex-col md:flex-row justify-between items-center">
                            <div className="flex-1 min-w-0">
                              <label
                                htmlFor={`quantity-${index}`}
                                className="block text-sm font-medium text-gray-300"
                              >
                                Cantidad:
                              </label>
                              <div className="flex items-center">
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    updateCartQuantity(
                                      index,
                                      item.quantity - 1
                                    );
                                  }}
                                  disabled={item.quantity === 1}
                                  className={`px-2 py-1 rounded-l-md border ${
                                    item.quantity === 1
                                      ? "bg-gray-600 cursor-not-allowed"
                                      : "bg-gray-500 hover:bg-gray-600"
                                  }`}
                                >
                                  -
                                </button>
                                <input
                                  type="number"
                                  id={`quantity-${index}`}
                                  className="w-12 text-center border-t border-b border-gray-600 bg-gray-700 text-gray-300"
                                  value={item.quantity}
                                  onChange={(e) =>
                                    updateCartQuantity(
                                      index,
                                      parseInt(e.target.value)
                                    )
                                  }
                                  onClick={(e) => e.stopPropagation()}
                                />
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    updateCartQuantity(
                                      index,
                                      item.quantity + 1
                                    );
                                  }}
                                  className="px-2 py-1 rounded-r-md border bg-gray-500 hover:bg-gray-600"
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <div className="flex-1 min-w-0 mt-4 md:mt-0 md:ml-4">
                              <label
                                htmlFor={`discount-${index}`}
                                className="block text-sm font-medium text-gray-300"
                              >
                                Descuento:
                              </label>
                              <input
                                type="number"
                                id={`discount-${index}`}
                                className="mt-1 block w-full border border-gray-600 rounded-md shadow-sm p-2 bg-gray-700 text-gray-300"
                                value={item.discount}
                                onChange={(e) => console.log(e.target.value)}
                                onClick={(e) => e.stopPropagation()}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                  <div className="flex justify-between items-center mt-4 p-4 border-t border-gray-600">
                    <span>Subtotal</span>
                    <span>₡{subtotal.toFixed(2)}</span>
                  </div>
                  <div className="flex justify-between items-center p-4">
                    <span>Impuesto</span>
                    <span>₡{tax.toFixed(2)}</span>
                  </div>
                  <div className="flex justify-between items-center font-bold text-lg p-4">
                    <span>Total</span>
                    <span>₡{total.toFixed(2)}</span>
                  </div>
                  <div className="flex justify-between items-center p-4 border-t border-gray-600">
                    <span>Método de Pago</span>
                    <select
                      value={paymentMethod}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      className="w-full px-3 py-2 border border-gray-600 rounded-md bg-gray-800 text-gray-300"
                    >
                      <option value="Tarjeta">Tarjeta</option>
                      <option value="Efectivo">Efectivo</option>
                      <option value="SinpeMovil/Transferencia">SinpeMovil/Transferencia</option>
                    </select>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 p-4">
                  <button
                    onClick={saveSale}
                    className="flex-1 bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded"
                    disabled={loading} // Deshabilitar el botón mientras carga
                  >
                    {loading ? (
                      <div className="flex justify-center items-center">
                        <svg
                          className="animate-spin h-5 w-5 mr-3"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291l2.664 2.665A8.001 8.001 0 014 12H0c0 2.042.791 3.884 2.091 5.291z"
                          ></path>
                        </svg>
                        Cargando...
                      </div>
                    ) : (
                      "Proceder"
                    )}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default NewSale;
