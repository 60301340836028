import React, { useState, useEffect } from 'react';
import { PDFViewer, pdf } from '@react-pdf/renderer';
import { InvoiceTemplate1, InvoiceTemplate2, InvoiceTemplate3 } from '../../Components/Billing/InvoiceTemplates';
import axios from 'axios';
import toast from 'react-hot-toast';

const Billing = () => {
    const [invoiceData] = useState({
        client: {
            name: 'OLIVIA WILSON',
            address: 'Calle Cualquiera 123, Cualquier Lugar',
            email: 'hola@unsitiogenial.es',
            phone: '+34-91-1234-567',
            cif: '911234567',
        },
        invoiceNumber: 'ES-001',
        date: '29/01/2028',
        items: [
            { quantity: 1, description: 'Desarrollo página web', unitPrice: 100, total: 100 },
            { quantity: 1, description: 'Desarrollo dashboard', unitPrice: 200, total: 200 },
            { quantity: 1, description: 'Analítico de datos', unitPrice: 500, total: 500 },
        ],
        total: 800,
    });

    const [template, setTemplate] = useState('template1');
    const [email, setEmail] = useState('');
    const [settings, setSettings] = useState({
        useBilling: false,
        billingMail: '',
        billingPassword: '',
        enableInvoices: false,
        selectedTemplate: 'template1', // Template por defecto
    });

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/settings`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                setSettings(response.data);
            } catch (error) {
                console.error('Error fetching settings:', error);
                toast.error('Error al obtener configuraciones');
            }
        };
        fetchSettings();
    }, []);

    const handleTemplateChange = (e) => {
        setTemplate(e.target.value);
        setSettings((prevSettings) => ({
            ...prevSettings,
            selectedTemplate: e.target.value,
        }));
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSettingsChange = (e) => {
        const { name, value, type, checked } = e.target;
        setSettings((prevSettings) => ({
            ...prevSettings,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const toggleUseBilling = () => {
        setSettings((prevSettings) => ({
            ...prevSettings,
            useBilling: !prevSettings.useBilling,
        }));
    };

    const saveSettings = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/settings`, settings, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            });
            toast.success('Configuraciones guardadas exitosamente');
        } catch (error) {
            console.error('Error al guardar configuraciones:', error);
            toast.error('Error al guardar configuraciones');
        }
    };

    const sendTestEmail = async () => {
        if (!email.trim()) {
            toast.error('Por favor ingrese un correo electrónico válido');
            return;
        }

        try {
            const pdfBlob = await generatePDFBlob();
            const reader = new FileReader();
            reader.readAsDataURL(pdfBlob);
            reader.onloadend = async function () {
                const base64data = reader.result;

                const data = {
                    email: email.trim(),
                    template,
                    pdfBase64: base64data,
                    clientName: invoiceData.client.name,
                    clientAddress: invoiceData.client.address,
                    clientPhone: invoiceData.client.phone,
                    total: invoiceData.total.toString(),
                    items: invoiceData.items,
                    useBilling: settings.useBilling,
                    billingMail: settings.billingMail,
                    billingPassword: settings.billingPassword,
                };

                console.log('Datos a enviar:', data);

                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/api/billing/send-test-email`,
                    data,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                            'Content-Type': 'application/json',
                        },
                    }
                );

                console.log('Respuesta del servidor:', response.data);
                toast.success('Correo de prueba enviado exitosamente');
            };
        } catch (error) {
            console.error('Error al enviar el correo de prueba:', error.response?.data || error.message);
            toast.error(error.response?.data?.message || 'Error al enviar el correo de prueba');
        }
    };

    const generatePDFBlob = async () => {
        let document;
        switch (template) {
            case 'template2':
                document = <InvoiceTemplate2 invoice={invoiceData} />;
                break;
            case 'template3':
                document = <InvoiceTemplate3 invoice={invoiceData} />;
                break;
            case 'template1':
            default:
                document = <InvoiceTemplate1 invoice={invoiceData} />;
                break;
        }
        const blob = await pdf(document).toBlob();
        return blob;
    };

    return (
        <div className="md:ml-[256px] p-8 min-h-screen flex flex-col overflow-hidden dark:bg-gray-900">
            <div className="mb-6">
                <h1 className="text-3xl font-bold text-gray-800 dark:text-gray-100">Editor de Facturas</h1>
            </div>
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6 mb-6">
                <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                    <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2 md:mb-0 md:mr-4">
                        Seleccionar Plantilla:
                    </label>
                    <select
                        value={template}
                        onChange={handleTemplateChange}
                        className="p-2 border rounded bg-gray-200 dark:bg-gray-700 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <option value="template1">Plantilla 1</option>
                        <option value="template2">Plantilla 2</option>
                        <option value="template3">Plantilla 3</option>
                    </select>
                </div>
                <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                    <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2 md:mb-0 md:mr-4">
                        Correo Electrónico:
                    </label>
                    <input
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        className="p-2 border rounded bg-gray-200 dark:bg-gray-700 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Ingrese el correo electrónico"
                    />
                </div>
                <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                    <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2 md:mb-0 md:mr-4">
                        Activar Facturación:
                    </label>
                    <button
                        onClick={toggleUseBilling}
                        className={`p-2 border rounded ${settings.useBilling ? 'bg-green-500' : 'bg-gray-200 dark:bg-gray-700 dark:text-gray-300'} focus:outline-none focus:ring-2 focus:ring-blue-500`}
                    >
                        {settings.useBilling ? 'Desactivar' : 'Activar'}
                    </button>
                </div>
                {/** 
                {settings.useBilling && (
                    <>
                        <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                            <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2 md:mb-0 md:mr-4">
                                Correo de Facturación:
                            </label>
                            <input
                                type="email"
                                name="billingMail"
                                value={settings.billingMail}
                                onChange={handleSettingsChange}
                                className="p-2 border rounded bg-gray-200 dark:bg-gray-700 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="Ingrese el correo de facturación"
                            />
                        </div>
                        <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                            <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2 md:mb-0 md:mr-4">
                                Contraseña de Facturación:
                            </label>
                            <input
                                type="password"
                                name="billingPassword"
                                value={settings.billingPassword}
                                onChange={handleSettingsChange}
                                className="p-2 border rounded bg-gray-200 dark:bg-gray-700 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="Ingrese la contraseña de facturación"
                            />
                        </div>
                    </>
                )}
                    */}
                <button
                    onClick={saveSettings}
                    className="w-full bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4"
                >
                    Guardar Configuraciones
                </button>
            </div>
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6 mb-6">
                <button
                    onClick={sendTestEmail}
                    className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                    Prueba el correo
                </button>
            </div>
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6 mb-6">
                <PDFViewer width="100%" height="600">
                    {template === 'template1' && <InvoiceTemplate1 invoice={invoiceData} />}
                    {template === 'template2' && <InvoiceTemplate2 invoice={invoiceData} />}
                    {template === 'template3' && <InvoiceTemplate3 invoice={invoiceData} />}
                </PDFViewer>
            </div>
        </div>
    );
};

export default Billing;
