import React, { useState, useEffect } from 'react';
import posaurImage from '../../assets/img/dinosaurlogin.jpg';
import toast from 'react-hot-toast';
import useAxios from '../../hook/useAxios';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import posaurlogo from "../../assets/img/posaurlogo.png";

function Login() {
    let [searchParams, setSearchParams] = useSearchParams();
    const [formState, setFormState] = useState(searchParams.get('resetToken') ? 'changePassword' : 'login'); // 'login', 'register', 'resetPassword'
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
    });

    const [showPassword, setShowPassword] = useState(false);
    const [notificationShown, setNotificationShown] = useState(false);

    const handleResetPasswordClick = () => {
        setFormState('resetPassword');
    };

    const handleRegisterClick = () => {
        setFormState('register');
    };

    const handleLoginClick = () => {
        setFormState('login');
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (formState === 'changePassword' && (!formData.password || !formData.confirmPassword || formData.password !== formData.confirmPassword)) {
            toast.error("Las contraseñas no coinciden o están vacías.");
            return;
        }

        switch (formState) {
            case 'login':
                loginAxios.execute();
                break;
            case 'register':
                registerAxios.execute();
                break;
            case 'resetPassword':
                resetPasswordAxios.execute();
                break;
            case 'changePassword':
                changePasswordAxios.execute();
                break;
            default:
                toast.error("Form state not recognized.");
                break;
        }
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        const resetToken = searchParams.get('resetToken');
        if (resetToken) {
            setFormState('changePassword');
            setFormData((prevData) => ({ ...prevData, resetToken }));
        }
        const verified = searchParams.get('verified');
        if (verified === 'true' && !notificationShown) {
            toast.success('¡Tu cuenta ha sido verificada con éxito!');
            setNotificationShown(true);
        } else if (verified === 'false' && !notificationShown) {
            toast.error('No se pudo verificar la cuenta. Inténtalo de nuevo.');
            setNotificationShown(true);
        }
    }, [searchParams, notificationShown]);

    const registerAxios = useAxios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/register`,
        data: formData
    }, (data) => {
        toast.success('¡Registro exitoso! Se enviará un correo de confirmación a la dirección proporcionada.');
        setFormData({
            name: '',
            email: '',
            password: '',
        });
        setFormState('login');
    }, (error) => {
        toast.error(error.message);
    });

    const loginAxios = useAxios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/login`,
        data: {
            email: formData.email,
            password: formData.password,
        }
    }, (data) => {
        if (data.token && data.role && data.plan && data.planExpiry) {
            localStorage.setItem('token', data.token);
            localStorage.setItem('role', data.role);
            localStorage.setItem('plan', data.plan);
            localStorage.setItem('planExpiry', data.planExpiry);
            toast.success('Inicio de sesión exitoso, redirigiendo...');
            setTimeout(() => {
                window.location.href = '/dashboard';
            }, 1500);
        } else {
            toast.error('Error al iniciar sesión. Por favor, inténtalo de nuevo.');
        }
    }, (error) => {
        if (error.response) {
            const errorMessage = error.response.data.message || "Error al iniciar sesión.";
            switch (error.response.status) {
                case 401:
                    toast.error("Credenciales incorrectas.");
                    break;
                case 403:
                    toast.error(errorMessage);
                    break;
                case 404:
                    toast.error("Credenciales incorrectas.");
                    break;
                default:
                    toast.error(errorMessage);
            }
        } else {
            toast.error("Error de conexión con el servidor.");
        }
    });

    const resetPasswordAxios = useAxios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/requestResetPassword`,
        data: {
            email: formData.email,
        }
    }, (response) => {
        toast.success('Enlace para restablecer contraseña enviado. Revisa tu correo electrónico.');
    }, (error) => {
        toast.error(error.response.data.message || "Error al enviar el enlace de restablecimiento.");
    });

    const changePasswordAxios = useAxios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/resetPassword`,
        data: {
            newPassword: formData.password,
            token: searchParams.get('resetToken')
        }
    }, (response) => {
        toast.success('Contraseña actualizada correctamente.');
        setFormState('login');
        setFormData({ name: '', email: '', password: '' });
    }, (error) => {
        toast.error(error.response.data.message || "Error al cambiar la contraseña.");
    });

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
            <div className="max-w-4xl w-full space-y-8 p-10 bg-gray-800 rounded-xl shadow-2xl z-10 relative">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className="hidden md:flex flex-col justify-between">
                        <div>
                            <h2 className="text-3xl font-bold text-blue-400 mb-2">Gestiona tu negocio al alcance de un clic.</h2>
                            <p className="text-gray-400">La forma inteligente y rápida de vender, gestionar y crecer.</p>
                        </div>
                        <div className="mb-4 flex justify-center">
                            <img src={posaurImage} alt="POSaur" className="max-w-xs h-auto rounded-lg" />
                        </div>
                    </div>

                    <div className="bg-gray-700 p-8 rounded-xl shadow-xl z-20 relative">
                        <div>
                            <h2 className="text-center text-4xl font-extrabold text-blue-400 p-2">
                                POSAUR
                            </h2>

                            {formState === 'login' && (
                                <>
                                    <form className="space-y-6 mt-4" onSubmit={handleFormSubmit}>
                                        <input type="email" name="email" id="email" placeholder="Correo electrónico" required className="w-full px-3 py-2 border border-gray-600 rounded-md bg-gray-800 text-gray-300" onChange={handleInputChange} />
                                        <div className="password-wrapper relative">
                                            <input type={showPassword ? 'text' : 'password'} name="password" id="password" placeholder="Contraseña" required className="w-full px-3 py-2 border border-gray-600 rounded-md bg-gray-800 text-gray-300" onChange={handleInputChange} />
                                            <button type="button" onClick={toggleShowPassword} className="toggle-password absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600">
                                                {showPassword ? 'Ocultar' : 'Mostrar'}
                                            </button>
                                        </div>
                                        <div className="text-right">
                                            <button type="button" onClick={handleResetPasswordClick} className="text-sm text-blue-400 hover:text-blue-600">Olvidaste la contraseña?</button>
                                        </div>
                                        <button type="submit" className="w-full py-2 px-4 bg-blue-500 hover:bg-blue-600 rounded-md text-white text-sm">Ingresar</button>
                                    </form>
                                    <div className="mt-6">
                                        <div className="relative">
                                            <div className="absolute inset-0 flex items-center">
                                                <div className="w-full border-t border-gray-600"></div>
                                            </div>
                                            <div className="relative flex justify-center text-sm">
                                            </div>
                                        </div>
                                        <div className="mt-6 grid grid-cols-2 gap-3">
                                            <div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {formState === 'resetPassword' && (
                                <>
                                    <form className="space-y-6 mt-4" onSubmit={handleFormSubmit}>
                                        <input type="email" name="email" id="email-reset" placeholder="Correo electrónico" required className="w-full px-3 py-2 border border-gray-600 rounded-md bg-gray-800 text-gray-300" onChange={handleInputChange} />
                                        <button type="submit" className="w-full py-2 px-4 bg-blue-500 hover:bg-blue-600 rounded-md text-white text-sm">Restablecer contraseña</button>
                                    </form>
                                </>
                            )}
                            {formState === 'changePassword' && (
                                <form className="space-y-6 mt-4" onSubmit={handleFormSubmit}>
                                    <div className="password-wrapper relative">
                                        <input type={showPassword ? 'text' : 'password'} name="password" id="password" placeholder="Nueva contraseña" required className="w-full px-3 py-2 border border-gray-600 rounded-md bg-gray-800 text-gray-300" onChange={handleInputChange} />
                                        <button type="button" onClick={toggleShowPassword} className="toggle-password absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600">
                                            {showPassword ? 'Ocultar' : 'Mostrar'}
                                        </button>
                                    </div>
                                    <div className="password-wrapper relative">
                                        <input type={showPassword ? 'text' : 'password'} name="confirmPassword" id="confirmPassword" placeholder="Confirme la contraseña" required className="w-full px-3 py-2 border border-gray-600 rounded-md bg-gray-800 text-gray-300" onChange={handleInputChange} />
                                        <button type="button" onClick={toggleShowPassword} className="toggle-password absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600">
                                            {showPassword ? 'Ocultar' : 'Mostrar'}
                                        </button>
                                    </div>
                                    <button type="submit" className="w-full py-2 px-4 bg-blue-500 hover:bg-blue-600 rounded-md text-white text-sm">Cambiar la contraseña</button>
                                </form>
                            )}

                            {formState === 'register' && (
                                <form className="space-y-6 mt-4" onSubmit={handleFormSubmit}>
                                    <input type="text" name="name" id="name" placeholder="Nombre completo" required className="w-full px-3 py-2 border border-gray-600 rounded-md bg-gray-800 text-gray-300" onChange={handleInputChange} />
                                    <input type="email" name="email" id="email" placeholder="Correo electrónico" required className="w-full px-3 py-2 border border-gray-600 rounded-md bg-gray-800 text-gray-300" onChange={handleInputChange} />
                                    <div className="password-wrapper relative">
                                        <input type={showPassword ? 'text' : 'password'} name="password" id="password" placeholder="Contraseña" required className="w-full px-3 py-2 border border-gray-600 rounded-md bg-gray-800 text-gray-300" onChange={handleInputChange} />
                                        <button type="button" onClick={toggleShowPassword} className="toggle-password absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600">
                                            {showPassword ? 'Ocultar' : 'Mostrar'}
                                        </button>
                                    </div>
                                    <button type="submit" className="w-full py-2 px-4 bg-blue-500 hover:bg-blue-600 rounded-md text-white text-sm">Registrar</button>
                                </form>
                            )}

                            <div className="mt-6 text-sm font-display font-semibold text-gray-300 text-center">
                                {formState !== 'register' && (
                                    <span>¿No tienes una cuenta? <button onClick={handleRegisterClick} className="cursor-pointer text-blue-400 hover:text-blue-600">Regístrate</button></span>
                                )}
                                {formState === 'register' && (
                                    <span>¿Ya tienes una cuenta? <button onClick={handleLoginClick} className="cursor-pointer text-blue-400 hover:text-blue-600">Inicia sesión</button></span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-900 z-0" />
        </div>
    );
}

export default Login;
