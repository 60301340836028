import React, { useState, useEffect } from 'react';
import useAxios from '../../hook/useAxios';
import { FaDollarSign, FaShoppingCart, FaBoxes, FaRegSadTear, FaTrash } from 'react-icons/fa';
import 'react-loading-skeleton/dist/skeleton.css';
import toast from 'react-hot-toast';
import Modal from '../../Components/Dashboard/Modal';

const Cashbox = () => {
    const [salesData, setSalesData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [settings, setSettings] = useState({ autoCut: false });
    const [cutBoxModalOpen, setCutBoxModalOpen] = useState(false);
    const [error, setError] = useState(false);
    const [allCashboxes, setAllCashboxes] = useState([]);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [cashboxToDelete, setCashboxToDelete] = useState(null);

    const token = localStorage.getItem('token');

    const fetchSalesData = useAxios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/cash/daily`,
        headers: { Authorization: `Bearer ${token}` }
    }, (data) => {
        setSalesData(data);
        setLoading(false);
    }, (error) => {
        console.error('Error fetching sales data:', error);
        toast.error('Error fetching sales data');
        setError(true);
        setLoading(false);
    });

    const fetchAllCashboxes = useAxios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/cash/all`,
        headers: { Authorization: `Bearer ${token}` }
    }, (data) => {
        setAllCashboxes(data);
    }, (error) => {
        console.error('Error fetching cashboxes:', error);
        toast.error('Error fetching cashboxes');
        setError(true);
    });

    const deleteCashbox = useAxios({
        method: 'delete',
        url: `${process.env.REACT_APP_API_URL}/api/cash/delete`, // URL base, el id se pasará en execute
        headers: { Authorization: `Bearer ${token}` }
    }, (data, id) => {
        toast.success('Corte de caja eliminado exitosamente');
        setAllCashboxes(prevCashboxes => prevCashboxes.filter(cashbox => cashbox._id !== id));
    }, (error) => {
        console.error('Error deleting cashbox:', error);
        toast.error('Error deleting cashbox');
    });

    const fetchSettings = useAxios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/cash/settings`,
        headers: { Authorization: `Bearer ${token}` }
    }, (data) => {
        setSettings(data);
    }, (error) => {
        console.error('Error fetching settings:', error);
        toast.error('Error fetching settings');
        setError(true);
    });

    const handleCutBox = useAxios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/cash/cutbox`,
        headers: { Authorization: `Bearer ${token}` },
    }, (data) => {
        toast.success('Corte de Caja realizado exitosamente');
        setCutBoxModalOpen(false);
        fetchSalesData.execute(); // Actualiza los datos de ventas después del corte
        fetchAllCashboxes.execute();
    }, (error) => {
        if (error.response && error.response.status === 400) {
            toast.error('Necesitas al menos una venta para realizar el corte de caja');
        } else {
            toast.error('Error realizando el corte de caja');
        }
        setCutBoxModalOpen(false);
    });

    useEffect(() => {
        if (!error && loading) {
            fetchSalesData.execute();
            fetchSettings.execute();
            fetchAllCashboxes.execute();
        }
    }, [error, loading]);

    const openCutBoxModal = () => {
        setCutBoxModalOpen(true);
    };

    const closeCutBoxModal = () => {
        setCutBoxModalOpen(false);
    };

    const confirmCutBox = () => {
        handleCutBox.execute();
    };

    const openDeleteModal = (id) => {
        setCashboxToDelete(id);
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setCashboxToDelete(null);
        setDeleteModalOpen(false);
    };

    const confirmDeleteCashbox = () => {
        if (cashboxToDelete) {
            deleteCashbox.execute({ url: `${process.env.REACT_APP_API_URL}/api/cash/delete/${cashboxToDelete}` }, cashboxToDelete);
            closeDeleteModal();
        }
    };

    

    const formattedDate = new Date().toLocaleDateString();

    return (
        <div className="md:ml-[256px] p-8 min-h-screen flex flex-col overflow-hidden text-white">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-semibold">Corte de Caja</h1>
                <div className="flex items-center">
                    <button
                        className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded ml-2"
                        onClick={openCutBoxModal}
                    >
                        Corte de Caja
                    </button>
                </div>
            </div>
            {salesData ? (
                <div className="bg-gray-800 shadow rounded-lg p-4">
                    <div className="mb-4 flex justify-between items-center">
                        <h2 className="text-xl font-semibold">Resumen del Día</h2>
                        <p className="text-gray-400">{formattedDate}</p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="bg-blue-700 p-4 rounded-lg shadow">
                            <h3 className="text-lg font-semibold flex items-center"><FaDollarSign className="mr-2" /> Total Ventas del Día</h3>
                            <p className="text-2xl">{salesData.totalSales}</p>
                        </div>
                        <div className="bg-green-700 p-4 rounded-lg shadow">
                            <h3 className="text-lg font-semibold flex items-center"><FaShoppingCart className="mr-2" /> Total Productos Vendidos</h3>
                            <p className="text-2xl">{salesData.totalProductsSold}</p>
                        </div>
                        <div className="bg-yellow-700 p-4 rounded-lg shadow">
                            <h3 className="text-lg font-semibold flex items-center"><FaBoxes className="mr-2" /> Total</h3>
                            <p className="text-2xl">{salesData.totalAmount}</p>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center h-full">
                    <FaRegSadTear className="text-6xl mb-4 text-gray-400" />
                    <p className="text-gray-400">No hay datos para mostrar</p>
                </div>
            )}

            <div className="mt-8">
                <h2 className="text-xl font-semibold mb-4">Historial de Cortes de Caja</h2>
                {allCashboxes.length > 0 ? (
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        {allCashboxes.map(cashbox => (
                            <div key={cashbox._id} className="bg-gray-800 shadow rounded-lg p-4 relative">
                                <h3 className="text-lg font-semibold mb-2">Corte del {new Date(cashbox.createdAt).toLocaleDateString()}</h3>
                                <p><strong>Total Ventas:</strong> {cashbox.totalSales}</p>
                                <p><strong>Total Productos Vendidos:</strong> {cashbox.totalProductsSold}</p>
                                <p><strong>Total:</strong> ₡{cashbox.totalAmount.toFixed(2)}</p>
                                <button
                                    className="absolute top-2 right-2 text-red-500 hover:text-red-700"
                                    onClick={() => openDeleteModal(cashbox._id)}
                                >
                                    <FaTrash />
                                </button>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="flex flex-col items-center justify-center h-full">
                        <FaRegSadTear className="text-6xl mb-4 text-gray-400" />
                        <p className="text-gray-400">No hay cortes de caja previos</p>
                    </div>
                )}
            </div>

            {cutBoxModalOpen && (
                <Modal onClose={closeCutBoxModal}>
                    <div className="p-4">
                        <h2 className="text-2xl font-bold mb-4">Confirmar Corte de Caja</h2>
                        <p>¿Estás seguro de que deseas realizar el corte de caja?</p>
                        <div className="mt-4 flex justify-end space-x-4">
                            <button
                                className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-bold py-2 px-4 rounded"
                                onClick={closeCutBoxModal}
                            >
                                Cancelar
                            </button>
                            <button
                                className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded"
                                onClick={confirmCutBox}
                            >
                                Confirmar
                            </button>
                        </div>
                    </div>
                </Modal>
            )}

            {deleteModalOpen && (
                <Modal onClose={closeDeleteModal}>
                    <div className="p-4">
                        <h2 className="text-2xl font-bold mb-4">Confirmar Eliminación</h2>
                        <p>¿Estás seguro de que deseas eliminar este corte de caja?</p>
                        <div className="mt-4 flex justify-end space-x-4">
                            <button
                                className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-bold py-2 px-4 rounded"
                                onClick={closeDeleteModal}
                            >
                                Cancelar
                            </button>
                            <button
                                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                                onClick={confirmDeleteCashbox}
                            >
                                Eliminar
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default Cashbox;
