import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import axios from 'axios';
import CalendarModal from '../../Components/Dashboard/CalendarModal';
import toast from 'react-hot-toast';
import io from 'socket.io-client';

const localizer = momentLocalizer(moment);

const CalendarPage = () => {
    const [events, setEvents] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [newEvent, setNewEvent] = useState({ title: '', start: new Date(), end: new Date() });
    const token = localStorage.getItem('token');
    const socket = io(process.env.REACT_APP_API_URL, {
        auth: { token }
    });

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/events`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                const fetchedEvents = response.data.map(event => ({
                    ...event,
                    start: new Date(event.start),
                    end: new Date(event.end)
                }));
                setEvents(fetchedEvents);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
    }, [token]);

    const handleSelectSlot = ({ start, end }) => {
        setNewEvent({ title: '', start: new Date(start), end: moment(end).subtract(1, 'second').toDate() });
        setSelectedEvent(null);
        setModalOpen(true);
    };

    const handleSelectEvent = (event) => {
        setSelectedEvent(event);
        setNewEvent({ title: event.title, start: new Date(event.start), end: new Date(event.end) });
        setModalOpen(true);
    };

    const handleSaveEvent = async () => {
        try {
            const eventToSave = {
                ...newEvent,
                title: newEvent.title.trim() === '' ? 'Sin título' : newEvent.title,
                start: moment(newEvent.start).format('YYYY-MM-DDTHH:mm:ss'),
                end: moment(newEvent.end).format('YYYY-MM-DDTHH:mm:ss')
            };

            if (selectedEvent) {
                const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/events/${selectedEvent._id}`, eventToSave, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setEvents(events.map(event => (event._id === response.data._id ? { ...response.data, start: new Date(response.data.start), end: new Date(response.data.end) } : event)));
                toast.success('Evento actualizado correctamente');
                socket.emit('event-updated', response.data);
            } else {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/events`, eventToSave, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setEvents([...events, { ...response.data, start: new Date(response.data.start), end: new Date(response.data.end) }]);
                toast.success('Evento creado correctamente');
                socket.emit('event-added', response.data);
            }
            setModalOpen(false);
            setSelectedEvent(null);
            setNewEvent({ title: '', start: new Date(), end: new Date() });
        } catch (error) {
            console.error('Error guardando el evento:', error);
            toast.error('Error guardando el evento');
        }
    };

    const handleDeleteEvent = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/events/${selectedEvent._id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setEvents(events.filter(event => event._id !== selectedEvent._id));
            toast.success('Evento eliminado correctamente');
            socket.emit('event-deleted', selectedEvent._id);
            setModalOpen(false);
            setSelectedEvent(null);
            setNewEvent({ title: '', start: new Date(), end: new Date() });
        } catch (error) {
            console.error('Error eliminando el evento:', error);
            toast.error('Error eliminando el evento');
        }
    };

    return (
        <div className="md:ml-[256px] p-8 min-h-screen flex flex-col overflow-hidden bg-gray-900 text-white">
            <h1 className="text-xl md:text-2xl font-semibold mb-4">Calendario</h1>
            <div className="flex justify-center">
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500 }}
                    selectable
                    onSelectSlot={handleSelectSlot}
                    onSelectEvent={handleSelectEvent}
                    eventPropGetter={() => ({
                        style: {
                            backgroundColor: '#1a202c', // Dark background for events
                            color: 'white', // White text for events
                            borderRadius: '5px',
                            border: 'none',
                        }
                    })}
                />
            </div>
            <CalendarModal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
                <div title={selectedEvent ? 'Editar Evento' : 'Agregar Evento'}>
                    <form className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-300">Título:</label>
                            <input
                                type="text"
                                className="mt-1 block w-full border border-gray-700 rounded-md shadow-sm p-2 bg-gray-800 text-white"
                                value={newEvent.title}
                                onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-300">Hora de Inicio:</label>
                            <input
                                type="time"
                                className="mt-1 block w-full border border-gray-700 rounded-md shadow-sm p-2 bg-gray-800 text-white"
                                value={moment(newEvent.start).format('HH:mm')}
                                onChange={(e) => {
                                    const start = moment(newEvent.start).set({ hour: e.target.value.split(':')[0], minute: e.target.value.split(':')[1] }).toDate();
                                    setNewEvent({ ...newEvent, start });
                                }}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-300">Hora de Fin:</label>
                            <input
                                type="time"
                                className="mt-1 block w-full border border-gray-700 rounded-md shadow-sm p-2 bg-gray-800 text-white"
                                value={moment(newEvent.end).format('HH:mm')}
                                onChange={(e) => {
                                    const end = moment(newEvent.end).set({ hour: e.target.value.split(':')[0], minute: e.target.value.split(':')[1] }).toDate();
                                    setNewEvent({ ...newEvent, end });
                                }}
                            />
                        </div>
                        {selectedEvent && (
                            <div className="text-sm text-gray-500">
                                Creado por: {selectedEvent.createdBy}
                            </div>
                        )}
                        <div className="flex justify-end space-x-4">
                            <button
                                type="button"
                                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                                onClick={handleSaveEvent}
                            >
                                {selectedEvent ? 'Actualizar' : 'Guardar'}
                            </button>
                            {selectedEvent && (
                                <button
                                    type="button"
                                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                                    onClick={handleDeleteEvent}
                                >
                                    Eliminar
                                </button>
                            )}
                        </div>
                    </form>
                </div>
            </CalendarModal>
        </div>
    );
};

export default CalendarPage;
