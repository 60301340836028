import React, { useState, useEffect } from 'react';
import Modal from '../../Components/Dashboard/Modal';
import toast from 'react-hot-toast';

const ModalClient = ({ isOpen, onClose, onSave, clientData }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        address: ''
    });

    useEffect(() => {
        if (clientData) {
            setFormData({
                name: clientData.name || '',
                email: clientData.email || '',
                phone: clientData.phone || '',
                address: clientData.address || ''
            });
        } else {
            setFormData({
                name: '',
                email: '',
                phone: '',
                address: ''
            });
        }
    }, [clientData, isOpen]); // Add isOpen to dependencies to reset form when modal opens

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = () => {
        if (!formData.name || !formData.email || !formData.phone || !formData.address) {
            toast.error('Todos los campos son obligatorios');
            return;
        }
        onSave(formData);
        setFormData({ // Reset form data after saving
            name: '',
            email: '',
            phone: '',
            address: ''
        });
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} title="Cliente">
            <div className="space-y-4">
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded bg-gray-800 text-white placeholder-gray-400 focus:border-blue-500 focus:ring-blue-500"
                    placeholder="Nombre"
                />
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded bg-gray-800 text-white placeholder-gray-400 focus:border-blue-500 focus:ring-blue-500"
                    placeholder="Correo Electrónico"
                />
                <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded bg-gray-800 text-white placeholder-gray-400 focus:border-blue-500 focus:ring-blue-500"
                    placeholder="Teléfono"
                />
                <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded bg-gray-800 text-white placeholder-gray-400 focus:border-blue-500 focus:ring-blue-500"
                    placeholder="Dirección"
                />
            </div>
            <div className="mt-4 flex justify-end space-x-4">
                <button onClick={handleSubmit} className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded">Guardar</button>
                <button onClick={onClose} className="bg-gray-500 hover:bg-gray-600 text-white py-2 px-4 rounded">Cancelar</button>
            </div>
        </Modal>
    );
};

export default ModalClient;
